import {ErrorAlert} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {TenantCompartmentSettings, useTenantApi} from "../../api";
import {useReloadTenant, useTenantId} from "../../tenant";
import {CompartmentSettingsEditForm} from "./CompartmentSettingsEditForm";

export function CompartmentSettings() {
	const tenantId = useTenantId();
	const {getTenantCompartmentSettings, updateTenantCompartmentSettings} = useTenantApi();
	const reloadTenant = useReloadTenant();
	const {loading, error, result} = useAsync(getTenantCompartmentSettings, [tenantId]);

	async function handleSave(compartmentSettings: TenantCompartmentSettings) {
		await updateTenantCompartmentSettings(tenantId, compartmentSettings);
		await reloadTenant();
	}

	if (error) {
		return <ErrorAlert error={error} />;
	}

	return <CompartmentSettingsEditForm loading={loading} onSave={handleSave} compartmentSettings={result} />;
}
