import {CardContent, CardHeader, Chip} from "@mui/material";
import {Filter, RadioGroup, SpringPageable} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {CompartmentQuery, CompartmentStates, useLocationApi} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationChip} from "../location/LocationChip";
import {LocationSelect} from "../location/LocationSelect";

export type CompartmentFilter = Omit<CompartmentQuery, keyof SpringPageable>;

interface CompartmentFilterProps {
	value: CompartmentFilter;
	onChange: (value: CompartmentFilter) => any;
}

export function CompartmentFilter({value, onChange}: CompartmentFilterProps) {
	const {t, s} = useLocalization();

	const {getLocation} = useLocationApi();

	const location = useAsync(async (locationId?: number) => {
		if (locationId) {
			return await getLocation(locationId);
		}
	}, [value.location]);

	return (
		<Filter
			label={t("filter.title")}
			active={[
				value.status && (
					<Chip
						label={`${t("compartments.status.title")}: ${t(`compartments.status.${value.status}`)}`}
						onDelete={() => onChange({...value, status: undefined})}
					/>
				),
				location.result && (
					<LocationChip
						location={location.result}
						onDelete={() => onChange({...value, location: undefined})}
					/>
				),
				value.compartmentSize && (
					<Chip
						label={`${t("compartments.size")}: ${value.compartmentSize}`}
						onDelete={() => onChange({...value, compartmentSize: undefined})}
					/>
				),
			]}
			labels={s("filter")}
		>
			<CardHeader title={t("compartments.status.title")} />
			<CardContent>
				<RadioGroup
					row
					value={value.status || "all"}
					onChange={newStatus => onChange({...value, status: newStatus == "all" ? undefined : newStatus})}
					options={["all", ...CompartmentStates]}
					renderLabel={s("compartments.status")}
				/>
			</CardContent>
			<CardHeader title={t("locations.single")} />
			<CardContent>
				<LocationSelect
					value={location.result ?? null}
					onChange={location => onChange({...value, location: location?.id})}
				/>
			</CardContent>
		</Filter>
	);
}
