import {ErrorAlert} from "@variocube/app-ui";
import React from "react";
import {FullScreenMessage} from "./FullScreenMessage";

export function FullScreenErrorAlert({error}: { error: any }) {
	return (
		<FullScreenMessage>
			<ErrorAlert error={error} />
		</FullScreenMessage>
	);
}
