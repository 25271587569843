import React from "react";
import {Route, Routes} from "react-router-dom";
import {BookingCreate} from "./BookingCreate";
import {BookingList} from "./BookingList";
import {BookingView} from "./BookingView";

export function Bookings() {
	return (
		<Routes>
			<Route index element={<BookingList />} />
			<Route path="new" element={<BookingCreate />} />
			<Route path=":bookingId" element={<BookingView />} />
		</Routes>
	);
}
