import {Box, CircularProgress, Typography} from "@mui/material";
import {FullScreenMessage} from "./FullScreenMessage";
import * as React from "react";

interface LoadingMessageProps {
    message: string;
}

export function LoadingMessage({message}: LoadingMessageProps) {
    return (
        <FullScreenMessage>
            <CircularProgress size="8rem"/>
            <Box marginTop={4}>
                <Typography variant="h4">
                    {message}
                </Typography>
            </Box>
        </FullScreenMessage>
    )
}