import {CardContent, CardHeader, Chip} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {
	Filter,
	Now,
	PlainDateTime,
	PlainDateTimePicker,
	RadioGroup,
	SpringPageable,
	tryParseInstant,
	useTemporalFormat,
} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {BookingQuery, Roles, useLocationApi, UserQuery} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationChip} from "../location/LocationChip";
import {LocationSelect} from "../location/LocationSelect";

export type UserFilter = Omit<UserQuery, keyof SpringPageable>;

interface UserFilterProps {
	value: UserFilter;
	onChange: (value: UserFilter) => any;
	onSearch: (value?: string) => any;
}

export function UserFilter({value, onChange, onSearch}: UserFilterProps) {
	const {t, s} = useLocalization();

	return (
		<Filter
			label={t("filter.title")}
			enableSearch
			onSearch={onSearch}
			active={[
				value.search && (
					<Chip
						label={`${t("filter.search")}: ${value.search}`}
						onDelete={() => onSearch(undefined)}
					/>
				),
			]}
			labels={s("filter")}
		>
			<CardContent>
				<RadioGroup
					label={t("users.roles.title")}
					value={value.role}
					onChange={newRole => onChange({...value, role: newRole == "all" ? undefined : newRole})}
					options={["all", ...Roles]}
					renderLabel={s("users.roles")}
				/>
			</CardContent>
		</Filter>
	);
}
