import {CardContent, CardHeader, Chip} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {
	Filter,
	Now,
	PlainDateTime,
	PlainDateTimePicker,
	SpringPageable,
	tryParseInstant,
	useTemporalFormat,
} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {BookingQuery, useLocationApi} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationChip} from "../location/LocationChip";
import {LocationSelect} from "../location/LocationSelect";

export type BookingFilter = Omit<BookingQuery, keyof SpringPageable>;

interface BookingFilterProps {
	value: BookingFilter;
	onChange: (value: BookingFilter) => any;
	onSearch: (value?: string) => any;
}

export const UserTimeZoneId = Now.zonedDateTimeISO().timeZone;

export function BookingFilter({value, onChange, onSearch}: BookingFilterProps) {
	const {t, s} = useLocalization();

	const {getLocation} = useLocationApi();

	const location = useAsync(async (locationId?: number) => {
		if (locationId) {
			return await getLocation(locationId);
		}
	}, [value.location]);

	const from = tryParseInstant(value.from)?.toZonedDateTimeISO(UserTimeZoneId).toPlainDateTime();
	const until = tryParseInstant(value.until)?.toZonedDateTimeISO(UserTimeZoneId).toPlainDateTime();

	const fromFormatted = useTemporalFormat({value: from});
	const untilFormatted = useTemporalFormat({value: until});

	function handleFromChange(from: PlainDateTime | null) {
		onChange({...value, from: from?.toZonedDateTime(UserTimeZoneId).toInstant().toString()});
	}

	function handleUntilChange(from: PlainDateTime | null) {
		onChange({...value, until: from?.toZonedDateTime(UserTimeZoneId).toInstant().toString()});
	}

	return (
		<Filter
			label={t("filter.title")}
			enableSearch
			onSearch={onSearch}
			active={[
				from && (
					<Chip
						label={`${t("bookings.from")}: ${fromFormatted}`}
						onDelete={() => onChange({...value, from: undefined})}
					/>
				),
				until && (
					<Chip
						label={`${t("bookings.until")}: ${untilFormatted}`}
						onDelete={() => onChange({...value, until: undefined})}
					/>
				),
				location.result && (
					<LocationChip
						location={location.result}
						onDelete={() => onChange({...value, location: undefined})}
					/>
				),
				value.search && (
					<Chip
						label={`${t("filter.search")}: ${value.search}`}
						onDelete={() => onSearch(undefined)}
					/>
				),
			]}
			labels={s("filter")}
		>
			<CardHeader title={t("bookings.timeframe")} />
			<CardContent>
				<Grid container spacing={2}>
					<Grid item>
						<PlainDateTimePicker
							label={t("bookings.from")}
							value={from ?? null}
							onChange={handleFromChange}
						/>
					</Grid>
					<Grid item>
						<PlainDateTimePicker
							label={t("bookings.until")}
							value={until ?? null}
							onChange={handleUntilChange}
						/>
					</Grid>
				</Grid>
			</CardContent>
			<CardHeader title={t("locations.single")} />
			<CardContent>
				<LocationSelect
					value={location.result ?? null}
					onChange={location => onChange({...value, location: location?.id})}
				/>
			</CardContent>
		</Filter>
	);
}
