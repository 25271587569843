import React from "react";
import {useCompartmentApi} from "../../api";
import {useNumericParam} from "../../utils/useParam";
import {AuditTimeline} from "../audit/AuditTimeline";

export function CompartmentAuditTimeline() {
	const compartmentId = useNumericParam("compartmentId");
	const {getCompartmentAuditLog} = useCompartmentApi();

	return <AuditTimeline load={getCompartmentAuditLog} param={compartmentId} />;
}
