import React from "react";
import {useTenantUserApi} from "../../api";
import {useParam} from "../../utils/useParam";
import {AuditTimeline} from "../audit/AuditTimeline";

export function UserAuditTimeline() {
	const username = useParam("username");
	const {getUserAuditLog} = useTenantUserApi();
	return <AuditTimeline load={getUserAuditLog} param={username} />;
}
