import {createQueryString} from "@variocube/app-ui";
import {useMemo} from "react";
import {TypeOf} from "zod";
import {createApiClient, getBaseUrl} from "./client";
import {get_Login, get_Logout, RefreshTokenQuery, TokenQuery} from "./client.gen";

export function useAuthApi() {
	return useMemo(() => createAuthApi(), []);
}

function createAuthApi() {
	const api = createApiClient();
	return {
		getToken: (query: TokenQuery) =>
			api.post("/api/auth/token", {
				body: query,
			}),
		refreshToken: (query: RefreshTokenQuery) =>
			api.post("/api/auth/refresh", {
				body: query,
			}),
		redirectToLogin: (query: TypeOf<get_Login["parameters"]>["query"]) => {
			location.href = `${getBaseUrl()}/api/auth/login?${createQueryString(query)}`;
		},
		redirectToLogout: (query: TypeOf<get_Logout["parameters"]>["query"]) => {
			location.href = `${getBaseUrl()}/api/auth/logout?${createQueryString(query)}`;
		},
	};
}
