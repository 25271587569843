import {Stack} from "@mui/material";
import {DataTableCellProps} from "@variocube/app-ui";
import React from "react";
import {Booking} from "../../api";
import {UserLinkChip} from "../user/UserChip";

export function BookingUsersCell({row}: DataTableCellProps<Booking>) {
	return (
		<Stack spacing={1} alignItems="flex-start">
			{row.users.map(user => <UserLinkChip key={user.username} user={user} />)}
		</Stack>
	);
}
