import React from "react";
import {Route, Routes} from "react-router-dom";
import {CompartmentCreate} from "./CompartmentCreate";
import {CompartmentEdit} from "./CompartmentEdit";
import {CompartmentList} from "./CompartmentList";
import {CompartmentView} from "./CompartmentView";

export function Compartments() {
	return (
		<Routes>
			<Route index element={<CompartmentList />} />
			<Route path="new" element={<CompartmentCreate />} />
			<Route path=":compartmentId" element={<CompartmentView />} />
			<Route path=":compartmentId/edit" element={<CompartmentEdit />} />
		</Routes>
	);
}
