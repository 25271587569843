import {IconButton} from "@mui/material";
import {BoxNumber, DataTableCellProps} from "@variocube/app-ui";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Compartment} from "../../api";
import {useTenantId} from "../../tenant";

export function CompartmentCell({row}: DataTableCellProps<Compartment>) {
	const tenantId = useTenantId();
	return (
		<IconButton component={RouterLink} to={`/${tenantId}/compartments/${row.id}`}>
			<BoxNumber number={row.number} />
		</IconButton>
	);
}
