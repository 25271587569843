import React from "react";
import {useLocationApi} from "../../api";
import {useNumericParam} from "../../utils/useParam";
import {AuditTimeline} from "../audit/AuditTimeline";

export function LocationAuditTimeline() {
	const locationId = useNumericParam("locationId");
	const {getLocationAuditLog} = useLocationApi();
	return <AuditTimeline load={getLocationAuditLog} param={locationId} />;
}
