import {CardContent, FormHelperText, Grid} from "@mui/material";
import {Checkbox, EditForm, TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {TenantAuditSettings} from "../../api";
import {useLocalization} from "../../i18n";

interface AuditSettingsEditFormProps {
	loading: boolean;
	auditSettings?: TenantAuditSettings;
	onSave: (auditSettings: TenantAuditSettings) => Promise<any>;
}

export function AuditSettingsEditForm(props: AuditSettingsEditFormProps) {
	const {t} = useLocalization();
	const {loading, auditSettings, onSave} = props;

	const [auditBookingRetrieval, setAuditBookingRetrieval] = useState(false);
	const [confirmBookingRetrieval, setConfirmBookingRetrieval] = useState(false);
	const [auditBookingRetrievalThrottle, setAuditBookingRetrievalThrottle] = useState("");

	useEffect(() => {
		if (auditSettings) {
			setAuditBookingRetrieval(auditSettings.auditBookingRetrieval);
			setConfirmBookingRetrieval(auditSettings.confirmBookingRetrieval);
			setAuditBookingRetrievalThrottle(auditSettings.auditBookingRetrievalThrottle);
		}
	}, [auditSettings]);

	async function handleSave() {
		await onSave({
			auditBookingRetrieval,
			confirmBookingRetrieval,
			auditBookingRetrievalThrottle,
		});
	}

	return (
		<EditForm loading={loading} labels={t} onSave={handleSave}>
			<CardContent>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<Checkbox
							label={t("settings.audit.auditBookingRetrieval.title")}
							checked={auditBookingRetrieval}
							onChange={setAuditBookingRetrieval}
						/>
						<FormHelperText>{t("settings.audit.auditBookingRetrieval.helperText")}</FormHelperText>
					</Grid>
					<Grid item xs={12}>
						<Checkbox
							label={t("settings.audit.confirmBookingRetrieval.title")}
							checked={confirmBookingRetrieval}
							onChange={setConfirmBookingRetrieval}
							disabled={!auditBookingRetrieval}
						/>
						<FormHelperText>{t("settings.audit.confirmBookingRetrieval.helperText")}</FormHelperText>
					</Grid>
					<Grid item xs={12} sm={10} md={8} lg={6}>
						<TextField
							label={t("settings.audit.auditBookingRetrievalThrottle.title")}
							value={auditBookingRetrievalThrottle}
							onChange={setAuditBookingRetrievalThrottle}
							disabled={!auditBookingRetrieval}
							fullWidth
						/>
						<FormHelperText>{t("settings.audit.auditBookingRetrievalThrottle.helperText")}</FormHelperText>
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	);
}
