import {Grid} from "@mui/material";
import * as React from "react";
import {PropsWithChildren} from "react";

export const FullScreenMessage = ({children}: PropsWithChildren<{}>) => {
    return (
        <Grid sx={{height: "100vh"}} container direction="column" justifyContent="center"
              alignItems="center">
            {children}
        </Grid>
    );
};
