import {AppShell} from "@variocube/app-ui";
import React, {PropsWithChildren} from "react";
import {FooterBar} from "./FooterBar";
import {SideNav} from "./SideNav";
import {TopNav} from "./TopNav";

export function SmaloxAppShell({children}: PropsWithChildren<{}>) {
	return (
		<AppShell appName={"smalox"} footer={<FooterBar />} sideNav={<SideNav />} topNav={<TopNav />}>
			{children}
		</AppShell>
	);
}
