import {useStorage} from "@variocube/app-ui";
import {useCallback, useEffect, useMemo} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

export function useSearchRestore(key: string) {
	const [storageValue, setStorageValue] = useStorage(key, "");
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (!window.location.search && storageValue) {
			navigate({search: storageValue}, {replace: true});
		}
	}, []);

	useEffect(() => setStorageValue(location.search), [location]);
}

export function useSearchParam<T extends string = string>(name: string, defaultValue?: T | undefined) {
	const navigate = useNavigate();
	const [params, setParams] = useSearchParams();

	const setter = useCallback((value: string | undefined) => {
		if (value && value != defaultValue) {
			params.set(name, value);
		}
		else {
			params.delete(name);
		}
		setParams(params);
		navigate({search: params.toString()}, {replace: true});
	}, [params, name, defaultValue]);

	return [
		params.get(name) || defaultValue,
		setter,
	] as const;
}

export function useNumericSearchParam(name: string, defaultValue?: number | undefined) {
	const [value, setValue] = useSearchParam(name, defaultValue?.toString());

	const setNumberValue = useCallback((value?: number) => {
		setValue(value?.toString());
	}, [setValue]);

	return [
		Number(value),
		setNumberValue,
	] as const;
}

export function useSearchParamBoolean(name: string, defaultValue: boolean) {
	const [value, setValue] = useSearchParam(name, defaultValue.toString());

	const setBooleanValue = useCallback((value?: boolean) => {
		setValue(value?.toString() ?? "");
	}, [setValue]);

	return [
		parseBoolean(value),
		setBooleanValue,
	] as const;
}

function parseBoolean(value?: string) {
	switch (value) {
		case true.toString():
			return true;
		case false.toString():
			return false;
	}
}

export function useSearchParamArray(name: string, separator: string = "|", defaultValue: string[] = []) {
	const serialize = useCallback((values: string[]) => values.join(separator), [separator]);
	const deserialize = useCallback((value: string) => value.split(separator), [separator]);

	const defaultValueStr = useMemo(() => serialize(defaultValue), [serialize, defaultValue]);

	const [valueStr, setValueStr] = useSearchParam(name, defaultValueStr);

	const value = useMemo(() => valueStr ? deserialize(valueStr) : defaultValue, [deserialize, valueStr, defaultValue]);
	const setValue = useCallback((value: string[]) => setValueStr(serialize(value)), [setValueStr, serialize]);

	return [value, setValue] as const;
}
