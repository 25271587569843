import {CardContent, Chip} from "@mui/material";
import {Filter, RadioGroup, SpringPageable} from "@variocube/app-ui";
import React from "react";
import {LocationQuery, LocationTypes} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationTypeSelect} from "./LocationTypeSelect";

export type LocationFilter = Omit<LocationQuery, keyof SpringPageable>;

interface LocationFilterProps {
	value: LocationFilter;
	onChange: (value: LocationFilter) => any;
	onSearch: (value?: string) => any;
}

export function LocationFilter({value, onChange, onSearch}: LocationFilterProps) {
	const {t, s} = useLocalization();

	return (
		<Filter
			label={t("filter.title")}
			enableSearch
			onSearch={onSearch}
			active={[
				value.type && (
					<Chip
						label={`${t("locations.type")}: ${t(`locations.types.${value.type}`)}`}
						onDelete={() => onChange({...value, type: undefined})}
					/>
				),
				value.search && (
					<Chip
						label={`${t("filter.search")}: ${value.search}`}
						onDelete={() => onSearch(undefined)}
					/>
				),
			]}
			labels={s("filter")}
		>
			<CardContent>
				<LocationTypeSelect value={value.type} onChange={type => onChange({...value, type})} />
			</CardContent>
		</Filter>
	);
}
