import {ErrorAlert} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {TenantAuditSettings, useTenantApi} from "../../api";
import {useReloadTenant, useTenantId} from "../../tenant";
import {AuditSettingsEditForm} from "./AuditSettingsEditForm";

export function AuditSettings() {
	const tenantId = useTenantId();
	const {getTenantAuditSettings, updateTenantAuditSettings} = useTenantApi();
	const reloadTenant = useReloadTenant();
	const {loading, error, result} = useAsync(getTenantAuditSettings, [tenantId]);

	async function handleSave(auditSettings: TenantAuditSettings) {
		await updateTenantAuditSettings(tenantId, auditSettings);
		await reloadTenant();
	}

	if (error) {
		return <ErrorAlert error={error} />;
	}

	return <AuditSettingsEditForm loading={loading} onSave={handleSave} auditSettings={result} />;
}
