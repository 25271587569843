export type Stage = "localhost" | "development" | "test" | "production";

export function getStage() {
	if (location.hostname == "localhost") {
		return "localhost";
	}
	if (location.hostname.includes("dev")) {
		return "development";
	}
	if (location.hostname.includes("test")) {
		return "test";
	}
	return "production";
}
