import {Button, Chip, Stack} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, Tabs} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {useCompartmentApi} from "../../api";
import {DisabledChip} from "../../controls/DisabledChip";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../../tenant";
import {useSearchParam} from "../../utils/search";
import {useNumericParam} from "../../utils/useParam";
import {LocationLinkChip} from "../location/LocationChip";
import {View, ViewHeader} from "../View";
import {CompartmentAuditTimeline} from "./CompartmentAuditTimeline";
import {CompartmentBookingList} from "./CompartmentBookingList";
import {CompartmentStatusChip} from "./CompartmentStatusChip";
import {LockChip} from "./LockChip";
import {LockStatusChip} from "./LockStatusChip";

export function CompartmentView() {
	const {t} = useLocalization();
	const tenantId = useTenantId();
	const compartmentId = useNumericParam("compartmentId");
	const [tab, setTab] = useSearchParam("tab", "bookings");

	const {getCompartment} = useCompartmentApi();

	const {loading, error, result: compartment} = useAsync(getCompartment, [compartmentId]);

	return (
		<View loading={loading} error={error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={".."}>{t("compartments.title")}</BreadcrumbLink>
				<BreadcrumbItem>{compartment?.number}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={t("compartments.view.title", compartment)}
				subTitle={
					<Stack direction="row" spacing={2}>
						{compartment && <LocationLinkChip location={compartment.location} />}
						{compartment?.size && <Chip label={compartment.size} />}
						{compartment?.lock?.id && <LockChip lock={compartment.lock.id} />}
					</Stack>
				}
				titleAdornment={
					<Stack direction="row" spacing={2}>
						{compartment?.lock?.status && <LockStatusChip status={compartment.lock.status} />}
						{compartment && !compartment.enabled && <DisabledChip />}
						{compartment && <CompartmentStatusChip booked={compartment.booked} />}
					</Stack>
				}
				actions={
					<Stack direction="row" spacing={2}>
						<Button
							variant="outlined"
							color="primary"
							disabled={!compartment || !compartment.bookable}
							component={Link}
							to={`/${tenantId}/bookings/new?compartment=${compartment?.id}`}
						>
							{t("bookings.create.title")}
						</Button>
						<Button variant="outlined" color="primary" component={Link} to="edit">
							{t("edit")}
						</Button>
					</Stack>
				}
			/>
			<Tabs
				value={tab}
				onChange={(_, tab) => setTab(tab)}
				items={[
					{label: t("bookings.title"), value: "bookings"},
					{label: t("audit.title"), value: "audit"},
				]}
			/>
			{tab == "bookings" && <CompartmentBookingList />}
			{tab == "audit" && <CompartmentAuditTimeline />}
		</View>
	);
}
