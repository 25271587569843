import {Chip} from "@mui/material";
import React from "react";
import {BookingStatus} from "../../api";
import {useLocalization} from "../../i18n";

interface BookingStatusChipProps {
	status: BookingStatus;
}

export function BookingStatusChip({status}: BookingStatusChipProps) {
	const {t} = useLocalization();

	return <Chip label={t(`bookings.status.${status}`)} color={getColor(status)} />;
}

function getColor(status: BookingStatus) {
	switch (status) {
		case "ACTIVE":
			return "success";
		case "FUTURE":
			return "info";
	}
}
