import {Lock, LockOpen} from "@mui/icons-material";
import {Avatar, Chip, ChipProps, useTheme} from "@mui/material";
import React from "react";
import {useLocalization} from "../../i18n";

interface LockStatusChipProps extends Pick<ChipProps, "variant"> {
	status: "OPEN" | "CLOSED";
}

export function LockStatusChip({status, ...chipProps}: LockStatusChipProps) {
	const {t} = useLocalization();
	const theme = useTheme();

	return (
		<Chip
			avatar={
				<Avatar sx={{bgcolor: theme.palette[getColor(status)].main}}>
					{getIcon(status)}
				</Avatar>
			}
			label={t(`locks.status.${status}`)}
			{...chipProps}
		/>
	);
}

function getColor(status: "OPEN" | "CLOSED") {
	switch (status) {
		case "OPEN":
			return "success";
		case "CLOSED":
			return "error";
	}
}

function getIcon(status: "OPEN" | "CLOSED") {
	switch (status) {
		case "OPEN":
			return <LockOpen />;
		case "CLOSED":
			return <Lock />;
	}
}
