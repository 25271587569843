import {Chip, ChipProps} from "@mui/material";
import React from "react";
import {useLocalization} from "../../i18n";

interface CompartmentStatusChipProps extends Pick<ChipProps, "variant" | "color"> {
	booked: boolean;
}

export function CompartmentStatusChip({booked, ...chipProps}: CompartmentStatusChipProps) {
	const {t} = useLocalization();
	return <Chip label={t(`compartments.status.${booked ? "BOOKED" : "AVAILABLE"}`)} {...chipProps} />;
}
