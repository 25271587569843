import {Box, Button, Container, Stack} from "@mui/material";
import {TextField, useStorage} from "@variocube/app-ui";
import React, {useState} from "react";
import {useLogin} from "./auth";
import {FullScreenMessage} from "./controls";
import {useLocalization} from "./i18n";
import {Logo} from "./Logo";

export function LoginForm() {
	const {t} = useLocalization();
	const login = useLogin();
	const [storedEmail, setStoredEmail] = useStorage("login.email", "");
	const [email, setEmail] = useState(storedEmail);

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setStoredEmail(email);
		login({email});
	}

	return (
		<FullScreenMessage>
			<Container maxWidth="xs">
				<form onSubmit={handleSubmit}>
					<Stack spacing={2} alignItems="center">
						<Box width="60%" pb={2}>
							<Logo />
						</Box>
						<TextField
							label={t("login.yourEmailAddress")}
							fullWidth
							value={email}
							onChange={setEmail}
						/>
						<Button type="submit" color="primary" variant="contained" fullWidth>
							{t("login.continueToLogin")}
						</Button>
					</Stack>
				</form>
			</Container>
		</FullScreenMessage>
	);
}
