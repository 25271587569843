import {ApiError} from "@variocube/app-ui";
import React, {Fragment, PropsWithChildren, useEffect} from "react";
import {useLogin} from "./auth";
import {FullScreenErrorAlert} from "./controls";
import {LoginForm} from "./LoginForm";
import {LoginPending} from "./LoginPending";

interface LoginStateProps {
	tenantId?: string;
	loading: boolean;
	error: any;
}

export function LoginState({loading, error, tenantId, children}: PropsWithChildren<LoginStateProps>) {
	const login = useLogin();

	const isUnauthenticated = error instanceof ApiError && error.status == 401;

	useEffect(() => {
		if (isUnauthenticated && tenantId) {
			login({tenantId});
		}
	}, [isUnauthenticated, tenantId]);

	if (loading) {
		return <LoginPending />;
	}
	if (error) {
		if (isUnauthenticated) {
			return <LoginForm />;
		}
		return <FullScreenErrorAlert error={error} />;
	}
	return <Fragment>{children}</Fragment>;
}
