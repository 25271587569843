import {Chip} from "@mui/material";
import {ErrorBoundary, VCThemeProvider} from "@variocube/app-ui";
import React from "react";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import {AuthenticationProvider, LoginHandler, LogoutHandler} from "./auth";
import {StorageLocalizationProvider} from "./i18n";
import {SmaloxAppShell} from "./shell/SmaloxAppShell";
import {Stage} from "./stage";
import {TenantProvider} from "./tenant";
import {Bookings} from "./tenant/booking";
import {Compartments} from "./tenant/compartment";
import {Locations} from "./tenant/location";
import {Settings} from "./tenant/settings";
import {TenantSelection} from "./tenant/TenantSelection";
import {Users} from "./tenant/user";
import {UserProvider} from "./user";
import {useParam} from "./utils/useParam";

interface AppProps {
	stage: Stage;
}

export function App({stage}: AppProps) {
	return (
		<VCThemeProvider>
			<ErrorBoundary>
				<StorageLocalizationProvider>
					<BrowserRouter>
						<AuthenticationProvider
							loginRedirectUri={`${location.origin}/auth`}
							logoutRedirectUri={`${location.origin}/logout`}
						>
							<Routes>
								<Route path="/">
									<Route path="auth" element={<LoginHandler />} />
									<Route path="logout" element={<LogoutHandler />} />
									<Route path="tenants" element={<TenantSelection />} />
									<Route path=":tenantId" element={<Tenant />}>
										<Route path="locations/*" element={<Locations />} />
										<Route path="compartments/*" element={<Compartments />} />
										<Route path="bookings/*" element={<Bookings />} />
										<Route path="users/*" element={<Users />} />
										<Route path="settings/*" element={<Settings />} />
										<Route index element={<Navigate to="bookings" replace />} />
									</Route>
									<Route index element={<Navigate to="tenants" replace />} />
								</Route>
							</Routes>
						</AuthenticationProvider>
					</BrowserRouter>
				</StorageLocalizationProvider>
			</ErrorBoundary>
			<StageIndicator stage={stage} />
		</VCThemeProvider>
	);
}

export function Tenant() {
	const tenantId = useParam("tenantId");
	return (
		<UserProvider tenantId={tenantId}>
			<TenantProvider tenantId={tenantId}>
				<SmaloxAppShell>
					<Outlet />
				</SmaloxAppShell>
			</TenantProvider>
		</UserProvider>
	);
}

export function StageIndicator({stage}: Pick<AppProps, "stage">) {
	return stage == "production"
		? null
		: (
			<Chip
				sx={{position: "fixed", top: -4, left: "50%", transform: "translateX(-50%)"}}
				color="warning"
				size="small"
				label={stage}
			/>
		);
}
