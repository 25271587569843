import {Chip, Stack} from "@mui/material";
import {Code} from "@variocube/app-ui";
import React from "react";
import {useLocalization} from "../../i18n";

export function LockChip({lock}: { lock: string }) {
	const {t} = useLocalization();
	return (
		<Chip
			variant="outlined"
			label={
				<Stack direction="row" alignItems="center" spacing={1} sx={{ml: -1}}>
					<Chip label={t("compartments.lock.title")} size="small" />
					<Code color="inherit">
						{lock}
					</Code>
				</Stack>
			}
		/>
	);
}
