import {Visibility} from "@mui/icons-material";
import {
	Box,
	Card,
	CardActions,
	CardHeader,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
} from "@mui/material";
import {
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumbs,
	ConfirmButton,
	Now,
	TemporalFormat,
	TemporalRangeFormat,
	tryParseInstant,
} from "@variocube/app-ui";
import React, {Fragment, useRef, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {useBookingApi, User, UserRef} from "../../api";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../../tenant";
import {UserIcon} from "../../theme";
import {useNumericParam} from "../../utils/useParam";
import {AuditTimeline} from "../audit/AuditTimeline";
import {CompartmentLinkIconButton} from "../compartment/CompartmentLinkIconButton";
import {LocationLinkChip} from "../location/LocationChip";
import {View, ViewHeader} from "../View";
import {BookingStatusChip} from "./BookingStatusChip";
import {BookingUserAddButton} from "./BookingUserAddButton";
import {BookingUserRemoveButton} from "./BookingUserRemoveButton";

export function BookingView() {
	const {t} = useLocalization();
	const tenantId = useTenantId();
	const bookingId = useNumericParam("bookingId");
	const {getBooking, addBookingUser, removeBookingUser, updateBooking, getBookingAuditLog} = useBookingApi();
	const {loading, error, result: booking, execute} = useAsync(getBooking, [bookingId]);
	const reloadAudit = useRef(() => Promise.resolve());

	const from = tryParseInstant(booking?.from);
	const until = tryParseInstant(booking?.until);

	async function reload() {
		await Promise.all([execute(bookingId), reloadAudit.current()]);
	}

	async function handleAddUsers(users: User[]) {
		for (const user of users) {
			await addBookingUser(bookingId, user.username);
		}
		await reload();
	}

	async function handleRemoveUser(user: UserRef) {
		await removeBookingUser(bookingId, user.username);
		await reload();
	}

	async function handleEndBooking() {
		if (booking) {
			const {name, from} = booking;
			await updateBooking(bookingId, {
				name,
				from,
				until: Now.instant().toString(),
			});
			await reload();
		}
	}

	return (
		<View loading={loading} error={error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={".."}>{t("bookings.title")}</BreadcrumbLink>
				<BreadcrumbItem>{booking?.id}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={t("bookings.view.title", booking)}
				subTitle={
					<Fragment>
						{from && until && <TemporalRangeFormat from={from} until={until} />}
						{from && !until && <TemporalFormat value={from} />}
					</Fragment>
				}
				titleAdornment={booking && <BookingStatusChip {...booking} />}
				actions={booking?.status == "ACTIVE" && (
					<ConfirmButton
						variant="outlined"
						cancel={t("cancel")}
						title={t("bookings.end.title")}
						onConfirm={handleEndBooking}
					>
						{t("bookings.end.message")}
					</ConfirmButton>
				)}
			/>
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4}>
						<Card>
							<CardHeader title={t("bookings.compartment")} />
							<CardActions>
								{booking && <CompartmentLinkIconButton compartment={booking.compartment} />}
								{booking && <LocationLinkChip location={booking.location} />}
							</CardActions>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Card>
							<CardHeader title={t("bookings.users.title")} />
							<List>
								{booking?.users.map(user => (
									<ListItem
										key={user.username}
									>
										<ListItemIcon>
											<UserIcon />
										</ListItemIcon>
										<ListItemText primary={user.displayName} secondary={user.email} />
										<ListItemSecondaryAction>
											<IconButton
												component={Link}
												to={`/${tenantId}/users/${user.username}`}
											>
												<Visibility />
											</IconButton>
											<BookingUserRemoveButton user={user} onRemove={handleRemoveUser} />
										</ListItemSecondaryAction>
									</ListItem>
								))}
							</List>
							<CardActions>
								<BookingUserAddButton onAddUsers={handleAddUsers} />
							</CardActions>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
					</Grid>
				</Grid>
			</Box>
			<Box pt={4}>
				<Typography variant="h2" textAlign="center">{t("audit.title")}</Typography>
				<AuditTimeline
					load={getBookingAuditLog}
					param={bookingId}
					reloadRef={reload => {
						reloadAudit.current = reload;
					}}
				/>
			</Box>
		</View>
	);
}
