import {Alert, AlertTitle, Button, Stack, Typography} from "@mui/material";
import React, {Fragment, PropsWithChildren, useState} from "react";
import {useLocalization} from "../../i18n";
import {useTenantSettings} from "../../tenant";

export function BookingRetrievalConfirmation({children}: PropsWithChildren<{}>) {
	const {audit} = useTenantSettings();
	const [confirmed, setConfirmed] = useState(false);
	const {t} = useLocalization();

	if (audit.auditBookingRetrieval && audit.confirmBookingRetrieval && !confirmed) {
		return (
			<Stack spacing={2} alignItems="center">
				<Alert severity="warning">
					<AlertTitle>{t("bookings.confirmRetrieval.title")}</AlertTitle>
					<Typography>{t("bookings.confirmRetrieval.message")}</Typography>
				</Alert>
				<Button variant="contained" onClick={() => setConfirmed(true)}>
					{t("bookings.confirmRetrieval.confirm")}
				</Button>
			</Stack>
		);
	}
	else {
		return <Fragment>{children}</Fragment>;
	}
}
