import {Grid, Link, Typography} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {useLocalization} from "../i18n";
import {useUser} from "../user";

export function FooterBar() {
	const {t} = useLocalization();
	const {superUser} = useUser();

	return (
		<Grid container spacing={2} sx={{px: 1, py: 0.5}} alignItems="center" displayPrint="none">
			<Grid item style={{flexGrow: 1}}>
				<Typography variant="overline" color="textSecondary">© SMALOX</Typography>
			</Grid>
			{superUser && (
				<Grid item>
					<Typography variant="overline">
						<Link component={RouterLink} to="/tenants" color="secondary">
							{t("tenant.switch")}
						</Link>
					</Typography>
				</Grid>
			)}
			<Grid item>
				<Typography variant="overline">
					<Link href="/docs/api" color="secondary" target="_blank">{t("api-docs")}</Link>
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="overline">
					<Link href="https://www.stiwa.com/impressum" target="_blank" color="secondary">
						{t("imprint")}
					</Link>
				</Typography>
			</Grid>
		</Grid>
	);
}
