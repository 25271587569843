import {Logout} from "@mui/icons-material";
import {IconButton, Stack, Tooltip} from "@mui/material";
import {LanguageSwitcher} from "@variocube/app-ui";
import React from "react";
import {useLogout} from "../auth";
import {SUPPORTED_LANGUAGES, useLocalization} from "../i18n";

export function TopNav() {
	const {t, language, setLanguage} = useLocalization();
	const logout = useLogout();

	return (
		<Stack
			direction="row"
			spacing={1}
			displayPrint="none"
			sx={{flex: 1}}
			justifyContent="flex-end"
			alignItems="center"
		>
			<LanguageSwitcher
				color="inherit"
				languages={SUPPORTED_LANGUAGES}
				language={language}
				setLanguage={setLanguage}
			/>
			<Tooltip title={t("logout.title")}>
				<IconButton onClick={logout}>
					<Logout />
				</IconButton>
			</Tooltip>
		</Stack>
	);
}
