import {Button} from "@mui/material";
import {DataTable, useDataTableStorage, useSpringPage, useSpringPageable, useStorage} from "@variocube/app-ui";
import React, {Fragment, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {useBookingApi} from "../../api";
import {useLocalization} from "../../i18n";
import {View, ViewHeader} from "../View";
import {BookingCell} from "./BookingCell";
import {BookingCompartmentCell} from "./BookingCompartmentCell";
import {BookingFilter} from "./BookingFilter";
import {BookingFromCell} from "./BookingFromCell";
import {BookingLocationCell} from "./BookingLocationCell";
import {BookingRetrievalConfirmation} from "./BookingRetrievalConfirmation";
import {BookingUntilCell} from "./BookingUntilCell";
import {BookingUsersCell} from "./BookingUsersCell";

export function BookingList() {
	const {t} = useLocalization();
	return (
		<View>
			<ViewHeader
				title={t("bookings.title")}
				actions={
					<Button variant="outlined" color="primary" component={RouterLink} to="new">
						{t("bookings.create.title")}
					</Button>
				}
			/>
			<BookingRetrievalConfirmation>
				<BookingListConfirmed />
			</BookingRetrievalConfirmation>
		</View>
	);
}

function BookingListConfirmed() {
	const {t} = useLocalization();

	const {queryBookings} = useBookingApi();

	const columns = useMemo(() => [
		{
			label: t("bookings.single"),
			field: "id",
			sortable: true,
			default: true,
			component: BookingCell,
		},
		{
			label: t("bookings.location"),
			field: "compartment.location.name",
			sortable: false,
			default: true,
			component: BookingLocationCell,
		},
		{
			label: t("bookings.compartment"),
			field: "compartment.number",
			sortable: false,
			default: true,
			component: BookingCompartmentCell,
		},
		{
			label: t("bookings.users.title"),
			field: "users.username",
			sortable: true,
			default: true,
			component: BookingUsersCell,
		},
		{
			label: t("bookings.from"),
			field: "from",
			sortable: true,
			default: true,
			component: BookingFromCell,
		},
		{
			label: t("bookings.until"),
			field: "until",
			sortable: true,
			default: true,
			component: BookingUntilCell,
		},
	], [t]);

	const {onPageChange, onSort, ...storage} = useDataTableStorage("BookingList.paging");
	const pageable = useSpringPageable(storage);

	const [filter, setFilter] = useStorage<BookingFilter>("BookingList.filter", {});

	const query = useMemo(() => ({
		...filter,
		...pageable,
	}), [filter, pageable]);

	const {loading, error, result} = useAsync(queryBookings, [query]);
	const {rows, page} = useSpringPage(result);

	function handleSearch(search: string | undefined) {
		setFilter({
			...filter,
			search,
		});
	}

	return (
		<Fragment>
			<BookingFilter value={filter} onChange={setFilter} onSearch={handleSearch} />
			<DataTable
				columns={columns}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				onSort={onSort}
				onPageChange={onPageChange}
				{...storage}
			/>
		</Fragment>
	);
}
