import {Params, useParams} from "react-router-dom";

export function useParam<P extends string>(param: P, defaultValue?: string): string {
	const value = useOptionalParam(param);
	if (!value && defaultValue) {
		return defaultValue;
	}
	if (!value) {
		throw new Error("Missing parameter: " + param);
	}
	return value;
}

export function useNumericParam<P extends string>(param: P): number {
	return Number(useParam(param));
}

export function useOptionalParam<P extends string>(param: P): string | undefined {
	const params = useParams<P>() as Params<P>;
	return params[param];
}
