import {CardContent, FormHelperText, Grid} from "@mui/material";
import {Checkbox, EditForm, IntegerNumberFormat, NumberField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {TenantBookingSettings} from "../../api";
import {useLocalization} from "../../i18n";

interface BookingSettingsEditFormProps {
	loading: boolean;
	bookingSettings?: TenantBookingSettings;
	onSave: (bookingSettings: TenantBookingSettings) => Promise<any>;
}

export function BookingSettingsEditForm(props: BookingSettingsEditFormProps) {
	const {t} = useLocalization();
	const {loading, bookingSettings, onSave} = props;

	const [enableUserBookingCreation, setEnableUserBookingCreation] = useState<boolean>(false);
	const [maxBookingsPerUser, setMaxBookingsPerUser] = useState<number | null>(null);

	useEffect(() => {
		if (bookingSettings) {
			setEnableUserBookingCreation(bookingSettings.enableUserBookingCreation);
			setMaxBookingsPerUser(bookingSettings.maxBookingsPerUser ?? null);
		}
	}, [bookingSettings]);

	async function handleSave() {
		await onSave({
			enableUserBookingCreation,
			maxBookingsPerUser: maxBookingsPerUser ?? undefined,
		});
	}

	return (
		<EditForm loading={loading} labels={t} onSave={handleSave}>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Checkbox
							label={t("settings.booking.enableUserBookingCreation.title")}
							checked={enableUserBookingCreation}
							onChange={setEnableUserBookingCreation}
						/>
						<FormHelperText>{t("settings.booking.enableUserBookingCreation.helperText")}</FormHelperText>
					</Grid>
					<Grid item xs={12} sm={8} md={6} lg={4}>
						<NumberField
							label={t("settings.booking.maxBookingsPerUser.title")}
							numberFormat={IntegerNumberFormat}
							numberValue={maxBookingsPerUser}
							onChangeNumber={setMaxBookingsPerUser}
							fullWidth
						/>
						<FormHelperText>{t("settings.booking.maxBookingsPerUser.helperText")}</FormHelperText>
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	);
}
