import {Alert, AlertTitle, Box, Button, Link, Snackbar, Stack, Typography} from "@mui/material";
import {ErrorAlert, Now, tryParseInstant, useStorage} from "@variocube/app-ui";
import * as React from "react";
import {createContext, Fragment, PropsWithChildren, useCallback, useContext, useEffect, useMemo} from "react";
import {useAsync, useAsyncAbortable, useAsyncCallback} from "react-async-hook";
import {Link as RouterLink, Navigate, useLocation, useSearchParams} from "react-router-dom";
import {useAuthApi} from "./api";
import {FullScreenMessage} from "./controls";
import {useLocalization} from "./i18n";
import {LoginState} from "./LoginState";

interface LoginOptions {
	email?: string;
	tenantId?: string;
}

interface AuthenticationContextContent {
	authentication?: AuthenticationStorage;
	setAuthentication: (authentication: AuthenticationStorage) => void;
	login: (options: LoginOptions) => void;
	logout: () => void;
	loginRedirectUri: string;
	logoutRedirectUri: string;
}

const AuthenticationContext = createContext<AuthenticationContextContent>({
	setAuthentication: () => void 0,
	login: () => void 0,
	logout: () => void 0,
	loginRedirectUri: "",
	logoutRedirectUri: "",
});

interface AuthenticationProviderProps {
	loginRedirectUri: string;
	logoutRedirectUri: string;
}

interface AuthenticationStorage {
	accessToken?: string;
	refreshToken?: string;
	idToken?: string;
	expires?: string;
}

export function AuthenticationProvider(
	{children, loginRedirectUri, logoutRedirectUri}: PropsWithChildren<AuthenticationProviderProps>,
) {
	const [authentication, setAuthentication] = useStorage<AuthenticationStorage>("auth", {});

	const {redirectToLogin, redirectToLogout} = useAuthApi();
	const location = useLocation();

	const authContext = useMemo(() => ({
		authentication,
		setAuthentication,
		login: (options: LoginOptions) =>
			redirectToLogin({
				...options,
				redirectUri: loginRedirectUri,
				state: location.pathname + location.search,
			}),
		logout: () => redirectToLogout({redirectUri: logoutRedirectUri}),
		loginRedirectUri,
		logoutRedirectUri,
	}), [authentication, setAuthentication, loginRedirectUri, logoutRedirectUri, location]);

	return (
		<AuthenticationContext.Provider value={authContext}>
			{children}
			<TokenRefresher />
		</AuthenticationContext.Provider>
	);
}

function sleep(timeoutMs: number, abortSignal: AbortSignal) {
	return new Promise((resolve, reject) => {
		const timeout = setTimeout(resolve, timeoutMs);
		abortSignal.onabort = () => {
			clearTimeout(timeout);
			reject();
		};
	});
}

export function LoginHandler() {
	const {getToken} = useAuthApi();
	const [search] = useSearchParams();
	const {loginRedirectUri, setAuthentication} = useContext(AuthenticationContext);

	const {loading, error, result} = useAsync((search: URLSearchParams, loginRedirectUri: string) => {
		const code = search.get("code");
		if (!code) {
			throw new Error("Did not find `code` query parameter.");
		}
		return getToken({code, redirectUri: loginRedirectUri});
	}, [search, loginRedirectUri]);

	useEffect(() => {
		if (result) {
			setAuthentication({
				accessToken: result.access_token,
				idToken: result.id_token,
				refreshToken: result.refresh_token,
				expires: Now.instant().add({seconds: result.expires_in}).toString(),
			});
		}
	}, [result]);

	return (
		<LoginState loading={loading} error={error}>
			<Navigate to={search.get("state") ?? "/"} />
		</LoginState>
	);
}

export function LogoutHandler() {
	const {t} = useLocalization();
	const {setAuthentication} = useContext(AuthenticationContext);
	useEffect(() => {
		setAuthentication({});
	}, []);

	return (
		<FullScreenMessage>
			<Stack spacing={2} alignItems="center">
				<Alert severity="success">
					<AlertTitle>{t("logout.success.title")}</AlertTitle>
					<Typography>{t("logout.success.message")}</Typography>
				</Alert>
				<Box>
					<Link color="primary" component={RouterLink} to="/">{t("logout.success.login")}</Link>
				</Box>
			</Stack>
		</FullScreenMessage>
	);
}

export function useAccessToken() {
	const authContext = useContext(AuthenticationContext);
	return authContext.authentication?.accessToken;
}

export function useLogin() {
	const authContext = useContext(AuthenticationContext);
	return authContext.login;
}

export function useLogout() {
	const authContext = useContext(AuthenticationContext);
	return authContext.logout;
}

function useRefreshToken() {
	const authContext = useContext(AuthenticationContext);
	const {authentication, setAuthentication} = authContext;
	const {refreshToken} = useAuthApi();

	return useCallback(async () => {
		if (authentication?.refreshToken) {
			const result = await refreshToken({
				refreshToken: authentication.refreshToken,
			});
			setAuthentication({
				accessToken: result.access_token,
				idToken: result.id_token,
				refreshToken: result.refresh_token || authentication.refreshToken,
				expires: Now.instant().add({seconds: result.expires_in}).toString(),
			});
		}
		else {
			throw new Error("No authentication or no refresh token found.");
		}
	}, [authentication]);
}

function TokenRefresher() {
	const {authentication} = useContext(AuthenticationContext);
	const refreshToken = useRefreshToken();
	const {loading, error, execute} = useAsyncCallback(refreshToken);

	// You can set this value manually in localStorage, if you want to debug refreshing tokens:
	const [debugTokenRefresh] = useStorage("debugTokenRefresh", false);

	const refreshOnExpiration = useAsyncAbortable(
		async (abortSignal: AbortSignal, authentication?: AuthenticationStorage) => {
			if (authentication?.expires && authentication.refreshToken) {
				const expires = tryParseInstant(authentication.expires);
				if (expires) {
					// number of seconds before expiration
					// random between 30 and 180 seconds
					const secondsBeforeExpiration = 30 + Math.floor(Math.random() * 150);

					const timeoutMs = Now.instant().until(expires)
						.subtract({seconds: secondsBeforeExpiration})
						.round("milliseconds")
						.total("milliseconds");

					console.log(`TokenRefresher: Access token will be refreshed in ${timeoutMs} ms.`);
					await sleep(timeoutMs, abortSignal);

					await refreshToken();
				}
			}
		},
		[authentication],
	);

	return (
		<Fragment>
			{error && (
				<Snackbar open>
					<ErrorAlert error={error} />
				</Snackbar>
			)}
			{debugTokenRefresh && (
				<Snackbar
					open={refreshOnExpiration.loading}
					message="TokenRefresher: Waiting for refresh on expiration..."
					action={
						<Button color="inherit" size="small" onClick={execute} disabled={loading}>
							Refresh Now
						</Button>
					}
				/>
			)}
		</Fragment>
	);
}
