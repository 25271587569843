import React, {SVGProps} from "react";

export function Logo(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			x="0px"
			y="0px"
			viewBox="0 0 502.3 121.8"
			{...props}
			fill="currentColor"
		>
			<path d="M.4,117.2v-20.7h5.5l7.8,10.7c.2.2.4.7.7,1.3.3.7.7,1.4,1.1,2.3-.1-.9-.2-1.6-.2-2.3,0-.7,0-1.3,0-1.8v-10.2h5.4v20.7h-5.4l-7.8-10.7c-.2-.2-.4-.7-.8-1.3-.3-.7-.7-1.4-1.1-2.3.1.9.2,1.6.2,2.3,0,.7,0,1.3,0,1.8v10.2H.4Z" />
			<path d="M46.8,106.9c0,1.5-.3,2.9-.8,4.2s-1.3,2.5-2.4,3.5c-1.1,1-2.3,1.8-3.6,2.4-1.4.6-2.8.8-4.2.8s-2.9-.3-4.3-.8c-1.4-.6-2.5-1.3-3.6-2.4-1.1-1-1.9-2.2-2.4-3.5-.6-1.3-.8-2.7-.8-4.2s.3-2.9.8-4.2c.6-1.3,1.4-2.5,2.4-3.5,1-1,2.2-1.8,3.6-2.4,1.4-.6,2.8-.8,4.3-.8s2.9.3,4.3.8c1.4.6,2.6,1.3,3.6,2.4s1.8,2.2,2.4,3.5c.6,1.3.8,2.7.8,4.2ZM35.7,113c1.6,0,2.9-.6,3.9-1.7,1-1.2,1.5-2.6,1.5-4.4s-.5-3.2-1.6-4.3-2.3-1.7-3.9-1.7-2.9.6-3.9,1.7c-1,1.2-1.5,2.6-1.5,4.4s.5,3.2,1.5,4.4c1,1.1,2.3,1.7,3.9,1.7Z" />
			<path d="M60.6,117.2v-20.7h1.9v9.7l9.4-9.7h2.5l-9.8,9.9,10.3,10.8h-2.7l-9.6-10.5v10.5h-1.9Z" />
			<path d="M77.7,117.2v-20.7h10.3v1.8h-8.4v6.5h8.4v1.8h-8.4v8.9h8.4v1.8h-10.3Z" />
			<path d="M97.5,117.2v-9.5l-6.9-11.2h2.3l5.6,9.5,5.6-9.5h2.2l-6.8,11.2v9.5h-1.9Z" />
			<path d="M107.7,113.8l1.6-.9c.3,1,.8,1.8,1.6,2.4.7.6,1.6.8,2.7.8s2.3-.4,3.1-1.2c.7-.8,1.1-1.8,1.1-3.2s-.2-1.8-.7-2.5c-.5-.7-1.6-1.4-3.2-2.3-2.1-1.1-3.5-2.1-4.2-2.9-.7-.8-1-1.8-1-3s.5-2.7,1.5-3.6c1-.9,2.3-1.4,3.9-1.4s1.9.2,2.7.7c.8.4,1.4,1.1,1.9,2l-1.5.9c-.3-.6-.7-1-1.3-1.4-.6-.3-1.2-.5-2-.5s-1.8.3-2.4.9c-.6.6-.9,1.4-.9,2.4,0,1.5,1.2,2.8,3.6,4.1.4.2.8.4,1,.5,1.8,1,2.9,1.9,3.5,2.8.6.9.9,2,.9,3.4s-.5,3.3-1.6,4.4c-1.1,1.1-2.6,1.6-4.4,1.6s-2.5-.3-3.5-1c-1-.7-1.7-1.7-2.3-3Z" />
			<path d="M125.7,113.6l2.1.9-3.7,7.3-1.2-.6,2.9-7.6Z" />
			<path d="M140.9,117.2v-20.7h5.5l7.8,10.7c.2.2.4.7.7,1.3.3.7.7,1.4,1.1,2.3-.1-.9-.2-1.6-.2-2.3,0-.7,0-1.3,0-1.8v-10.2h5.4v20.7h-5.4l-7.8-10.7c-.2-.2-.4-.7-.8-1.3-.3-.7-.7-1.4-1.1-2.3.1.9.2,1.6.2,2.3,0,.7,0,1.3,0,1.8v10.2h-5.5Z" />
			<path d="M187.3,106.9c0,1.5-.3,2.9-.8,4.2s-1.3,2.5-2.4,3.5c-1.1,1-2.3,1.8-3.6,2.4-1.4.6-2.8.8-4.2.8s-2.9-.3-4.3-.8c-1.4-.6-2.5-1.3-3.6-2.4-1.1-1-1.9-2.2-2.4-3.5-.6-1.3-.8-2.7-.8-4.2s.3-2.9.8-4.2c.6-1.3,1.4-2.5,2.4-3.5,1-1,2.2-1.8,3.6-2.4,1.4-.6,2.8-.8,4.3-.8s2.9.3,4.3.8c1.4.6,2.6,1.3,3.6,2.4s1.8,2.2,2.4,3.5c.6,1.3.8,2.7.8,4.2ZM176.3,113c1.6,0,2.9-.6,3.9-1.7,1-1.2,1.5-2.6,1.5-4.4s-.5-3.2-1.6-4.3-2.3-1.7-3.9-1.7-2.9.6-3.9,1.7c-1,1.2-1.5,2.6-1.5,4.4s.5,3.2,1.5,4.4c1,1.1,2.3,1.7,3.9,1.7Z" />
			<path d="M217.1,100.3c-1-.9-2.1-1.5-3.2-2s-2.2-.6-3.4-.6c-2.7,0-4.9.8-6.5,2.5-1.7,1.7-2.5,3.9-2.5,6.6s.2,2.6.6,3.7c.4,1.1,1,2.1,1.9,2.9.8.8,1.8,1.5,2.9,1.9,1.1.4,2.2.7,3.4.7s2.4-.2,3.5-.6c1.1-.4,2.2-1,3.2-1.8v2.2c-1,.6-2.1,1.1-3.2,1.4-1.1.3-2.3.5-3.4.5s-2.9-.3-4.3-.8-2.5-1.3-3.5-2.3c-1-1-1.8-2.2-2.3-3.5-.5-1.3-.8-2.8-.8-4.3s.3-3,.8-4.3c.5-1.3,1.3-2.5,2.3-3.5,1-1,2.1-1.7,3.5-2.2,1.4-.5,2.8-.8,4.3-.8s2.5.2,3.7.5c1.1.4,2.2.9,3.1,1.6v2.1Z" />
			<path d="M218.8,117.2l9.1-21.6,8.7,21.6h-2.1l-2.6-7h-8.5l-2.7,7h-1.9ZM224.1,108.6h7.2l-3.5-9-3.7,9Z" />
			<path d="M239.4,117.2v-20.7h3.6c1.6,0,2.7,0,3.4.2.7.2,1.3.4,1.8.8.7.5,1.2,1.2,1.6,1.9.4.8.5,1.6.5,2.5s-.3,2-.9,2.8c-.6.8-1.4,1.3-2.4,1.5,1.3.2,2.4.7,3.1,1.6.8.9,1.1,2,1.1,3.3s-.1,1.8-.4,2.6c-.3.8-.7,1.4-1.3,2-.6.6-1.3,1-2.2,1.2-.8.2-2.3.3-4.3.3h-3.6ZM241.3,105.7h2.4c1.7,0,2.9-.3,3.7-.8.7-.6,1.1-1.5,1.1-2.8s-.3-2.4-1-3c-.7-.6-1.9-.9-3.7-.9h-2.4v7.6ZM241.3,115.6h2.4c1.4,0,2.3,0,2.8-.2s1-.3,1.3-.6c.4-.3.8-.8,1.1-1.4s.4-1.2.4-1.9-.1-1.6-.4-2.2-.7-1.1-1.2-1.4c-.4-.2-.8-.4-1.3-.4-.5,0-1.2-.1-2.3-.1h-2.8v8.3Z" />
			<path d="M255.9,117.2v-20.7h1.9v19h7.1v1.8h-9Z" />
			<path d="M268.3,117.2v-20.7h10.3v1.8h-8.4v6.5h8.4v1.8h-8.4v8.9h8.4v1.8h-10.3Z" />
			<path d="M282,113.8l1.6-.9c.3,1,.8,1.8,1.6,2.4s1.6.8,2.7.8,2.3-.4,3.1-1.2c.7-.8,1.1-1.8,1.1-3.2s-.2-1.8-.7-2.5c-.5-.7-1.6-1.4-3.2-2.3-2.1-1.1-3.5-2.1-4.2-2.9-.7-.8-1-1.8-1-3s.5-2.7,1.5-3.6c1-.9,2.3-1.4,3.9-1.4s1.9.2,2.7.7c.8.4,1.4,1.1,1.9,2l-1.5.9c-.3-.6-.7-1-1.3-1.4-.6-.3-1.2-.5-2-.5s-1.8.3-2.4.9c-.6.6-.9,1.4-.9,2.4,0,1.5,1.2,2.8,3.6,4.1.4.2.8.4,1,.5,1.8,1,2.9,1.9,3.5,2.8.6.9.9,2,.9,3.4s-.5,3.3-1.6,4.4c-1.1,1.1-2.6,1.6-4.4,1.6s-2.5-.3-3.5-1c-1-.7-1.7-1.7-2.3-3Z" />
			<path d="M300,113.6l2.1.9-3.7,7.3-1.2-.6,2.9-7.6Z" />
			<path d="M315.2,117.2v-20.7h5.5l7.8,10.7c.2.2.4.7.7,1.3.3.7.7,1.4,1.1,2.3-.1-.9-.2-1.6-.2-2.3,0-.7,0-1.3,0-1.8v-10.2h5.4v20.7h-5.4l-7.8-10.7c-.2-.2-.4-.7-.8-1.3-.3-.7-.7-1.4-1.1-2.3.1.9.2,1.6.2,2.3,0,.7,0,1.3,0,1.8v10.2h-5.5Z" />
			<path d="M361.6,106.9c0,1.5-.3,2.9-.8,4.2-.5,1.3-1.3,2.5-2.4,3.5-1.1,1-2.3,1.8-3.6,2.4-1.4.6-2.8.8-4.2.8s-2.9-.3-4.3-.8c-1.4-.6-2.5-1.3-3.6-2.4-1.1-1-1.9-2.2-2.4-3.5-.6-1.3-.8-2.7-.8-4.2s.3-2.9.8-4.2c.6-1.3,1.4-2.5,2.4-3.5,1-1,2.2-1.8,3.6-2.4,1.4-.6,2.8-.8,4.3-.8s2.9.3,4.3.8c1.4.6,2.6,1.3,3.6,2.4s1.8,2.2,2.4,3.5c.6,1.3.8,2.7.8,4.2ZM350.5,113c1.6,0,2.9-.6,3.9-1.7,1-1.2,1.5-2.6,1.5-4.4s-.5-3.2-1.6-4.3-2.3-1.7-3.9-1.7-2.9.6-3.9,1.7c-1,1.2-1.5,2.6-1.5,4.4s.5,3.2,1.5,4.4c1,1.1,2.3,1.7,3.9,1.7Z" />
			<path d="M374.7,117.2v-20.7h3.6c1.6,0,2.7,0,3.4.2.7.2,1.3.4,1.8.8.7.5,1.2,1.2,1.6,1.9.4.8.5,1.6.5,2.5s-.3,2-.9,2.8c-.6.8-1.4,1.3-2.4,1.5,1.3.2,2.4.7,3.1,1.6.8.9,1.1,2,1.1,3.3s-.1,1.8-.4,2.6c-.3.8-.7,1.4-1.3,2-.6.6-1.3,1-2.2,1.2-.8.2-2.3.3-4.3.3h-3.6ZM376.6,105.7h2.3c1.7,0,2.9-.3,3.7-.8s1.1-1.5,1.1-2.8-.3-2.4-1-3c-.7-.6-1.9-.9-3.7-.9h-2.3v7.6ZM376.6,115.6h2.4c1.4,0,2.3,0,2.8-.2.5-.1,1-.3,1.3-.6.4-.3.8-.8,1.1-1.4.3-.6.4-1.2.4-1.9s-.1-1.6-.4-2.2-.7-1.1-1.2-1.4c-.4-.2-.8-.4-1.3-.4-.5,0-1.2-.1-2.3-.1h-2.8v8.3Z" />
			<path d="M388.4,117.2l9.1-21.6,8.7,21.6h-2.1l-2.6-7h-8.5l-2.7,7h-1.9ZM393.7,108.6h7.2l-3.5-9-3.7,9Z" />
			<path d="M411.1,117.2v-19h-5.7v-1.8h13.3v1.8h-5.7v19h-1.9Z" />
			<path d="M425.9,117.2v-19h-5.7v-1.8h13.3v1.8h-5.7v19h-1.9Z" />
			<path d="M437.1,117.2v-20.7h10.3v1.8h-8.4v6.5h8.4v1.8h-8.4v8.9h8.4v1.8h-10.3Z" />
			<path d="M465,117.2h-2.3l-7.3-9.9h-1.1v9.9h-1.9v-20.7h3.8c2.2,0,3.9.5,5,1.4,1.1.9,1.7,2.4,1.7,4.2s-.5,2.9-1.4,3.8c-1,.9-2.3,1.4-4,1.4l7.4,9.9ZM454.3,98.1v7.7h1.9c1.8,0,3-.3,3.7-.8.7-.6,1-1.5,1-2.9s-.4-2.4-1.1-3c-.7-.6-1.9-.9-3.6-.9h-1.9Z" />
			<path d="M468.1,117.2v-20.7h1.9v20.7h-1.9Z" />
			<path d="M475.6,117.2v-20.7h10.3v1.8h-8.4v6.5h8.4v1.8h-8.4v8.9h8.4v1.8h-10.3Z" />
			<path d="M489.3,113.8l1.6-.9c.3,1,.8,1.8,1.6,2.4s1.6.8,2.7.8,2.3-.4,3.1-1.2c.7-.8,1.1-1.8,1.1-3.2s-.2-1.8-.7-2.5c-.5-.7-1.6-1.4-3.2-2.3-2.1-1.1-3.5-2.1-4.2-2.9-.7-.8-1-1.8-1-3s.5-2.7,1.5-3.6c1-.9,2.3-1.4,3.9-1.4s1.9.2,2.7.7c.8.4,1.4,1.1,1.9,2l-1.5.9c-.3-.6-.7-1-1.3-1.4-.6-.3-1.2-.5-2-.5s-1.8.3-2.4.9c-.6.6-.9,1.4-.9,2.4,0,1.5,1.2,2.8,3.6,4.1.4.2.8.4,1,.5,1.8,1,2.9,1.9,3.5,2.8.6.9.9,2,.9,3.4s-.5,3.3-1.6,4.4c-1.1,1.1-2.6,1.6-4.4,1.6s-2.5-.3-3.5-1c-1-.7-1.7-1.7-2.3-3Z" />
			<path d="M39.2,55.6h-11.4C12.5,55.6,0,43.2,0,27.8S12.5,0,27.8,0h49.9v11H27.8c-9.3,0-16.8,7.5-16.8,16.8s7.5,16.8,16.8,16.8h11.4v11Z" />
			<polygon points="339.7 78.2 276.3 78.2 276.3 .1 287.3 .1 287.3 67.2 339.7 67.2 339.7 78.2" />
			<path d="M378.5,78.2c-21.4,0-38.9-17.5-38.9-39.1S357,0,378.5,0s38.9,17.5,38.9,39.1-17.4,39.1-38.9,39.1ZM378.5,11c-15.4,0-27.9,12.6-27.9,28.1s12.5,28.1,27.9,28.1,27.9-12.6,27.9-28.1-12.5-28.1-27.9-28.1Z" />
			<polygon points="431.9 78.2 424.1 70.4 455.4 39.1 424.1 7.8 431.9 0 471 39.1 431.9 78.2" />
			<path d="M50,78.2H0v-11h49.9c9.3,0,16.8-7.5,16.8-16.8s-7.5-16.8-16.8-16.8h-11.4v-11h11.4c15.3,0,27.8,12.5,27.8,27.8s-12.5,27.8-27.8,27.8Z" />
			<rect x="466.2" y="11.7" width="37.7" height="11" transform="translate(129.9 348) rotate(-45)" />
			<rect x="479.6" y="42.2" width="11" height="37.7" transform="translate(98.9 360.9) rotate(-45)" />
			<path d="M147.8,0c-6.7,0-12.6,3-16.7,7.6C127,3,121,0,114.3,0c-12.3,0-22.2,10-22.2,22.2v55.8h11V22.3c0-6.2,5-11.2,11.2-11.2s11.2,5,11.2,11.2v55.8h11V22.3c0-6.2,5-11.2,11.2-11.2s11.2,5,11.2,11.2v55.8h11V22.3C170,10,160,0,147.8,0Z" />
			<path d="M223.1,0c-21.5,0-38.9,17.5-38.9,38.9v39.2h11v-39.2c0-15.4,12.5-27.9,27.9-27.9s27.9,12.5,27.9,27.9v14.2h-37.4s0,11,0,11h37.4v14h11v-39.2C262,17.5,244.6,0,223.1,0Z" />
		</svg>
	);
}
