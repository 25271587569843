import {SpringPageable} from "@variocube/app-ui";
import {useMemo} from "react";
import {TypeOf} from "zod";
import {useAccessToken} from "../auth";
import {useTenantId} from "../tenant";
import {createApiClient, UserRoleMutation} from "./client";
import {get_QueryUsers} from "./client.gen";

export function useUserApi() {
	const accessToken = useAccessToken();
	return useMemo(() => createUserApi(accessToken), [accessToken]);
}

export function useTenantUserApi() {
	const accessToken = useAccessToken();
	const tenantId = useTenantId();
	return useMemo(() => createTenantUserApi(tenantId, accessToken), [tenantId, accessToken]);
}

export type UserQuery = TypeOf<get_QueryUsers["parameters"]>["query"];
export type Role = UserQuery["role"];

export const Roles = [
	"ADMIN",
	"MANAGER",
	"USER",
] as const;

function createUserApi(accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		getCurrentUser: () => api.get("/api/user"),
	};
}

function createTenantUserApi(tenantId: string, accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		getUser: (username: string) =>
			api.get("/api/tenants/{tenantId}/users/{username}", {
				path: {tenantId, username},
			}),
		deleteUser: (username: string) =>
			api.delete("/api/tenants/{tenantId}/users/{username}", {
				path: {tenantId, username},
			}),
		queryUsers: (query: UserQuery) =>
			api.get("/api/tenants/{tenantId}/users", {
				path: {tenantId},
				query,
			}),
		getUserRoles: (username: string) =>
			api.get("/api/tenants/{tenantId}/users/{username}/roles", {
				path: {tenantId, username},
			}),
		createUserRole: (username: string, role: UserRoleMutation) =>
			api.post("/api/tenants/{tenantId}/users/{username}/roles", {
				path: {tenantId, username},
				body: role,
			}),
		updateUserRole: (username: string, userRoleId: number, role: UserRoleMutation) =>
			api.put("/api/tenants/{tenantId}/users/{username}/roles/{userRoleId}", {
				path: {tenantId, username, userRoleId},
				body: role,
			}),
		deleteUserRole: (username: string, userRoleId: number) =>
			api.delete("/api/tenants/{tenantId}/users/{username}/roles/{userRoleId}", {
				path: {tenantId, username, userRoleId},
			}),
		getUserBookings: (username: string, pageable: SpringPageable) =>
			api.get("/api/tenants/{tenantId}/users/{username}/bookings", {
				path: {tenantId, username},
				query: pageable,
			}),
		getUserAuditLog: (username: string, pageable: SpringPageable) =>
			api.get("/api/tenants/{tenantId}/users/{username}/audit", {
				path: {tenantId, username},
				query: pageable,
			}),
	};
}
