import {Chip, ChipProps, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {Booking} from "../../api";
import {useTenantId} from "../../tenant";
import {BookingIcon} from "../../theme";

interface BookingChipProps extends Omit<ChipProps, "label" | "icon"> {
	booking: Booking;
}

export function BookingChip({booking, ...chipProps}: BookingChipProps) {
	return (
		<Chip
			icon={<BookingIcon />}
			label={<BookingLabel booking={booking} />}
			{...chipProps}
		/>
	);
}

export function BookingLinkChip({booking}: { booking: Booking }) {
	const tenantId = useTenantId();
	return (
		<Chip
			icon={<BookingIcon />}
			label={<BookingLabel booking={booking} />}
			variant="outlined"
			color="secondary"
			clickable
			component={Link}
			to={`/${tenantId}/bookings/${booking.id}`}
		/>
	);
}

function BookingLabel({booking}: { booking: Booking }) {
	return (
		<Typography fontFamily="JetBrains Mono" fontSize="large">
			{booking.id}
		</Typography>
	);
}
