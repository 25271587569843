import {SpringPageable} from "@variocube/app-ui";
import {useMemo} from "react";
import {TypeOf} from "zod";
import {useAccessToken} from "../auth";
import {useTenantId} from "../tenant";
import {Booking, createApiClient} from "./client";
import {BookingCreation, BookingMutation, get_QueryBookings} from "./client.gen";

export function useBookingApi() {
	const accessToken = useAccessToken();
	const tenantId = useTenantId();
	return useMemo(() => createBookingApi(tenantId, accessToken), [tenantId, accessToken]);
}

export type BookingQuery = TypeOf<get_QueryBookings["parameters"]>["query"];
export type BookingStatus = Booking["status"];

function createBookingApi(tenantId: string, accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		getBooking: (bookingId: number) =>
			api.get("/api/tenants/{tenantId}/bookings/{bookingId}", {
				path: {tenantId, bookingId},
			}),
		updateBooking: (bookingId: number, mutation: BookingMutation) =>
			api.put("/api/tenants/{tenantId}/bookings/{bookingId}", {
				path: {tenantId, bookingId},
				body: mutation,
			}),
		deleteBooking: (bookingId: number) =>
			api.delete("/api/tenants/{tenantId}/bookings/{bookingId}", {
				path: {tenantId, bookingId},
			}),
		createBooking: (bookingCreation: BookingCreation) =>
			api.post("/api/tenants/{tenantId}/bookings", {
				path: {tenantId},
				body: bookingCreation,
			}),
		queryBookings: (query: BookingQuery) =>
			api.get("/api/tenants/{tenantId}/bookings", {
				path: {tenantId},
				query,
			}),
		getBookingAuditLog: (bookingId: number, pageable: SpringPageable) =>
			api.get("/api/tenants/{tenantId}/bookings/{bookingId}/audit", {
				path: {tenantId, bookingId},
				query: pageable,
			}),
		addBookingUser: (bookingId: number, username: string) =>
			api.post("/api/tenants/{tenantId}/bookings/{bookingId}/users/{username}", {
				path: {tenantId, bookingId, username},
			}),
		removeBookingUser: (bookingId: number, username: string) =>
			api.delete("/api/tenants/{tenantId}/bookings/{bookingId}/users/{username}", {
				path: {tenantId, bookingId, username},
			}),
	};
}
