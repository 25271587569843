import {
	Apartment,
	Domain,
	HolidayVillage,
	HouseSiding,
	ListAlt,
	LocationCity,
	Map,
	MeetingRoom,
} from "@mui/icons-material";
import React from "react";
import {LocationType} from "../../api";

export function LocationIcon({type}: { type: LocationType }) {
	switch (type) {
		case "COUNTRY":
			return <Map />;
		case "CITY":
			return <LocationCity />;
		case "BUILDING":
			return <Apartment />;
		case "AREA":
			return <Domain />;
		case "ESTATE":
			return <HolidayVillage />;
		case "WING":
			return <HouseSiding />;
		case "FLOOR":
			return <HouseSiding />;
		case "ROOM":
			return <MeetingRoom />;
		case "LOCKER_BANK":
		default:
			return <ListAlt />;
	}
}
