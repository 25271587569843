import {Box, Container, ContainerProps, Grid, Stack, Typography} from "@mui/material";
import {ErrorAlert, PageTitle} from "@variocube/app-ui";
import React, {PropsWithChildren} from "react";

export interface ViewProps {
	maxWidth?: ContainerProps["maxWidth"];
	loading?: boolean;
	error?: any;
}

export function View({maxWidth, loading, error, children}: PropsWithChildren<ViewProps>) {
	return (
		<Container maxWidth={maxWidth}>
			<Stack spacing={2} sx={{opacity: loading ? 0.5 : 1}}>
				{error && <ErrorAlert error={error} />}
				{children}
			</Stack>
		</Container>
	);
}

export interface ViewHeaderProps {
	title: string;
	titleAdornment?: React.ReactNode;
	subTitle?: React.ReactNode;
	actions?: React.ReactNode;
}

export function ViewHeader(props: PropsWithChildren<ViewHeaderProps>) {
	const {title, titleAdornment, subTitle, actions} = props;
	return (
		<Box>
			<Grid container spacing={2} justifyContent="space-between">
				<Grid item>
					<Box>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<PageTitle title={title} />
							</Grid>
							{titleAdornment && (
								<Grid item>
									{titleAdornment}
								</Grid>
							)}
							{subTitle && (
								<Grid item xs={12}>
									<Typography variant="subtitle1">
										{subTitle}
									</Typography>
								</Grid>
							)}
						</Grid>
					</Box>
				</Grid>
				{actions && (
					<Grid item>
						{actions}
					</Grid>
				)}
			</Grid>
		</Box>
	);
}
