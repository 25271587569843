import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {CompartmentMutation, useCompartmentApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useNumericParam} from "../../utils/useParam";
import {View, ViewHeader} from "../View";
import {CompartmentEditForm} from "./CompartmentEditForm";

export function CompartmentEdit() {
	const {t} = useLocalization();
	const compartmentId = useNumericParam("compartmentId");
	const navigate = useNavigate();

	const {getCompartment, updateCompartment} = useCompartmentApi();
	const {loading, error, result: compartment} = useAsync(getCompartment, [compartmentId]);

	async function handleSave(mutation: CompartmentMutation) {
		await updateCompartment(compartmentId, mutation);
		navigate(-1);
	}

	return (
		<View error={error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={"../.."} relative="path">{t("compartments.title")}</BreadcrumbLink>
				<BreadcrumbLink component={Link} to={".."} relative="path">{compartment?.number}</BreadcrumbLink>
				<BreadcrumbItem>{t("edit")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("compartments.edit.title", compartment)} />
			<CompartmentEditForm loading={loading} compartment={compartment} onSave={handleSave} />
		</View>
	);
}
