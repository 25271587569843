import {CardContent, FormHelperText, Grid, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {EditForm} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {TenantCompartmentSettings} from "../../api";
import {useLocalization} from "../../i18n";

interface CompartmentSettingsEditFormProps {
	loading: boolean;
	compartmentSettings?: TenantCompartmentSettings;
	onSave: (compartmentSettings: TenantCompartmentSettings) => Promise<any>;
}

export function CompartmentSettingsEditForm(props: CompartmentSettingsEditFormProps) {
	const {t} = useLocalization();
	const {loading, compartmentSettings, onSave} = props;

	const [compartmentSizes, setCompartmentSizes] = useState<string[]>([]);

	useEffect(() => {
		if (compartmentSettings) {
			setCompartmentSizes(compartmentSettings.compartmentSizes);
		}
	}, [compartmentSettings]);

	async function handleSave() {
		await onSave({
			compartmentSizes,
		});
	}

	return (
		<EditForm loading={loading} labels={t} onSave={handleSave}>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={8} md={6} lg={4}>
						<Autocomplete
							value={compartmentSizes}
							options={compartmentSizes}
							multiple
							freeSolo
							onChange={(_, value) => setCompartmentSizes(value)}
							fullWidth
							renderInput={props => (
								<TextField
									label={t("settings.compartment.compartmentSizes.title")}
									{...props}
								/>
							)}
						/>
						<FormHelperText>{t("settings.compartment.compartmentSizes.helperText")}</FormHelperText>
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	);
}
