import {Search} from "@mui/icons-material";
import {IconButton, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {BoxNumber, TextField, useFlag} from "@variocube/app-ui";
import React, {Fragment, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Compartment, CompartmentStatus, LocationTree, useCompartmentApi} from "../../api";
import {useLocalization} from "../../i18n";
import {CompartmentSearch} from "./CompartmentSearch";

export interface CompartmentSelectProps {
	value: Compartment | null;
	onChange: (compartment: Compartment | null) => void;
	status?: CompartmentStatus;
	location?: LocationTree | null;
	required?: boolean;
}

export function CompartmentSelect({value, onChange, status, location, required}: CompartmentSelectProps) {
	const {t} = useLocalization();
	const {queryCompartments} = useCompartmentApi();

	const query = useMemo(() => ({
		size: 1000,
		status,
		locationId: location?.id,
	} as const), [status, location]);

	const {loading, error, result} = useAsync(queryCompartments, [query]);

	const [searchOpen, setSearchOpen, clearSearchOpen] = useFlag(false);
	return (
		<Fragment>
			<Autocomplete
				loading={loading}
				options={result?.content ?? []}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t("compartments.single")}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<IconButton onClick={setSearchOpen}>
									<Search />
								</IconButton>
							),
						}}
						required={required}
					/>
				)}
				getOptionLabel={(compartment) => `${compartment.number} - ${compartment.location.name}`}
				renderOption={(props, compartment, state) => (
					<ListItem {...props} selected={state.selected}>
						<ListItemIcon>
							<BoxNumber number={compartment.number} />
						</ListItemIcon>
						<ListItemText primary={compartment.number} secondary={compartment.location.name} />
					</ListItem>
				)}
				onChange={(_, value) => onChange(value)}
				value={value}
			/>

			<CompartmentSearch
				onCompartmentSelected={compartment => {
					onChange(compartment);
					clearSearchOpen();
				}}
				open={searchOpen}
				onClose={clearSearchOpen}
			/>
		</Fragment>
	);
}
