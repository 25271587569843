import React from "react";
import {Route, Routes} from "react-router-dom";
import {LocationList} from "./LocationList";
import {LocationManagement} from "./LocationManagement";
import {LocationView} from "./LocationView";

export function Locations() {
	return (
		<Routes>
			<Route path=":locationId" element={<LocationView />} />
			<Route path="manage" element={<LocationManagement />} />
			<Route index element={<LocationList />} />
		</Routes>
	);
}
