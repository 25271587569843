import {Alert, AlertTitle, Box, Card, List, ListItemButton, ListItemText} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {TenantQuery, useTenantApi} from "../api";
import {FullScreenMessage} from "../controls";
import {useLocalization} from "../i18n";
import {LoginState} from "../LoginState";
import {View, ViewHeader} from "./View";

export function TenantSelection() {
	const {t} = useLocalization();
	const {queryTenants} = useTenantApi();
	const navigate = useNavigate();

	const [query, setQuery] = useState<TenantQuery>({});
	const {loading, error, result} = useAsync(queryTenants, [query]);

	// Automatically navigate to tenant, if there is only one
	useEffect(() => {
		if (result) {
			if (result.content?.length == 1) {
				navigate(`/${result.content[0].id}`);
			}
		}
	}, [result]);

	if (result?.totalElements === 0) {
		return (
			<FullScreenMessage>
				<Alert severity="error">
					<AlertTitle>{t("tenant.selection.noTenants.title")}</AlertTitle>
					{t("tenant.selection.noTenants.description")}
				</Alert>
			</FullScreenMessage>
		);
	}

	return (
		<LoginState loading={loading} error={error}>
			<Box py={4}>
				<View maxWidth="md">
					<ViewHeader title={t("tenant.selection.title")} />
					<Card>
						<List>
							{result?.content?.map((tenant) => (
								<ListItemButton key={tenant.id} component={Link} to={`/${tenant.id}`}>
									<ListItemText primary={tenant.name} />
								</ListItemButton>
							))}
						</List>
					</Card>
				</View>
			</Box>
		</LoginState>
	);
}
