import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Tenant, TenantSettings, useTenantApi} from "./api";
import {LoginState} from "./LoginState";

interface TenantContextContent {
	tenant?: Tenant;
	settings?: TenantSettings;
	reload?: () => any;
}

const TenantContext = createContext<TenantContextContent>({});

interface TenantProviderProps {
	tenantId: string;
}

export function TenantProvider({tenantId, children}: PropsWithChildren<TenantProviderProps>) {
	const {getTenant, getTenantSettings} = useTenantApi();

	const [tenant, setTenant] = useState<Tenant>();
	const [settings, setSettings] = useState<TenantSettings>();

	const {error, result, execute} = useAsync(
		(tenantId: string) =>
			Promise.all([
				getTenant(tenantId),
				getTenantSettings(tenantId),
			]),
		[tenantId],
	);

	useEffect(() => {
		if (result) {
			const [tenant, settings] = result;
			setTenant(tenant);
			setSettings(settings);
		}
	}, [result]);

	const reload = useCallback(() => execute(tenantId), [execute, tenantId]);

	return (
		<LoginState loading={!tenant} error={error} tenantId={tenantId}>
			<TenantContext.Provider value={{tenant, settings, reload}}>
				{children}
			</TenantContext.Provider>
		</LoginState>
	);
}

export function useTenantId() {
	const {tenant} = useContext(TenantContext);
	if (!tenant) {
		throw new Error("No tenant found in context. You are probably calling this from outside a TenantProvider.");
	}
	return tenant.id;
}

export function useTenantSettings() {
	const {settings} = useContext(TenantContext);
	if (!settings) {
		throw new Error("No tenant found in context. You are probably calling this from outside a TenantProvider.");
	}
	return settings;
}

export function useReloadTenant() {
	const {reload} = useContext(TenantContext);
	if (!reload) {
		throw new Error("No tenant found in context. You are probably calling this from outside a TenantProvider.");
	}
	return reload;
}
