import {DataTable, DataTableCellProps, useDataTableStorage, useSpringPage, useSpringPageable} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Compartment, useLocationApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useNumericParam} from "../../utils/useParam";
import {CompartmentCell} from "../compartment/CompartmentCell";
import {CompartmentStatusChip} from "../compartment/CompartmentStatusChip";
import {LockStatusChip} from "../compartment/LockStatusChip";

export function LocationCompartmentList() {
	const locationId = useNumericParam("locationId");
	const {t} = useLocalization();
	const {getLocationCompartments} = useLocationApi();
	const {onPageChange, onSort, ...storage} = useDataTableStorage("CompartmentBookingList.paging");
	const pageable = useSpringPageable(storage);
	const {loading, error, result} = useAsync(getLocationCompartments, [locationId, pageable]);
	const {rows, page} = useSpringPage(result);

	const columns = useMemo(() => [
		{
			label: t("compartments.number"),
			field: "number",
			sortable: true,
			default: true,
			component: CompartmentCell,
		},
		{
			label: t("compartments.size"),
			field: "size",
			sortable: true,
			default: true,
		},
		{
			label: t("compartments.status.title"),
			field: "booked",
			sortable: false,
			default: true,
			component: ({row}: DataTableCellProps<Compartment>) => <CompartmentStatusChip booked={row.booked} />,
		},
		{
			label: t("compartments.lock.status"),
			field: "lock.status",
			sortable: false,
			default: true,
			component: ({row}: DataTableCellProps<Compartment>) =>
				row.lock?.status ? <LockStatusChip status={row.lock.status} /> : null,
		},
	], [t]);

	return (
		<DataTable
			columns={columns}
			rows={rows}
			page={page}
			loading={loading}
			error={error}
			onSort={onSort}
			onPageChange={onPageChange}
			{...storage}
		/>
	);
}
