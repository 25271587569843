import {PersonRemove} from "@mui/icons-material";
import {ConfirmIconButton} from "@variocube/app-ui";
import React from "react";
import {UserRef} from "../../api";
import {useLocalization} from "../../i18n";

interface BookingUserRemoveButtonProps {
	user: UserRef;
	onRemove: (user: UserRef) => Promise<any>;
}

export function BookingUserRemoveButton({user, onRemove}: BookingUserRemoveButtonProps) {
	const {t} = useLocalization();
	return (
		<ConfirmIconButton
			title={t("bookings.users.remove")}
			cancel={t("cancel")}
			onConfirm={() => onRemove(user)}
			icon={<PersonRemove />}
			color="error"
		>
			{t("bookings.users.confirmRemove", user)}
		</ConfirmIconButton>
	);
}
