import z from "zod";

export type TenantMutation = z.infer<typeof TenantMutation>;
export const TenantMutation = z.object({
	name: z.string().optional(),
});

export type Tenant = z.infer<typeof Tenant>;
export const Tenant = z.object({
	id: z.string(),
	name: z.string(),
});

export type UserRoleMutation = z.infer<typeof UserRoleMutation>;
export const UserRoleMutation = z.object({
	locationId: z.union([z.number(), z.undefined()]).optional(),
	role: z.union([z.literal("ADMIN"), z.literal("MANAGER"), z.literal("USER")]),
});

export type LocationRef = z.infer<typeof LocationRef>;
export const LocationRef = z.object({
	id: z.number(),
	name: z.string(),
});

export type TenantRef = z.infer<typeof TenantRef>;
export const TenantRef = z.object({
	id: z.string(),
	name: z.string(),
});

export type UserRole = z.infer<typeof UserRole>;
export const UserRole = z.object({
	id: z.number(),
	username: z.string(),
	tenant: TenantRef,
	location: z.union([LocationRef, z.undefined()]).optional(),
	role: z.union([z.literal("ADMIN"), z.literal("MANAGER"), z.literal("USER")]),
});

export type TenantCompartmentSettings = z.infer<typeof TenantCompartmentSettings>;
export const TenantCompartmentSettings = z.object({
	compartmentSizes: z.array(z.string()),
});

export type TenantBookingSettings = z.infer<typeof TenantBookingSettings>;
export const TenantBookingSettings = z.object({
	enableUserBookingCreation: z.boolean(),
	maxBookingsPerUser: z.union([z.number(), z.undefined()]).optional(),
});

export type TenantAuditSettings = z.infer<typeof TenantAuditSettings>;
export const TenantAuditSettings = z.object({
	auditBookingRetrieval: z.boolean(),
	confirmBookingRetrieval: z.boolean(),
	auditBookingRetrievalThrottle: z.string(),
});

export type LockKeyMutation = z.infer<typeof LockKeyMutation>;
export const LockKeyMutation = z.object({
	key: z.string().optional(),
});

export type LocationMutation = z.infer<typeof LocationMutation>;
export const LocationMutation = z.object({
	name: z.string(),
	type: z
		.union([
			z.literal("COUNTRY"),
			z.literal("CITY"),
			z.literal("AREA"),
			z.literal("ESTATE"),
			z.literal("BUILDING"),
			z.literal("WING"),
			z.literal("FLOOR"),
			z.literal("ROOM"),
			z.literal("LOCKER_BANK"),
			z.undefined(),
		])
		.optional(),
	parentId: z.union([z.number(), z.undefined()]).optional(),
	enabled: z.boolean(),
});

export type LocationAncestry = {
	id: number;
	name: string;
	enabled: boolean;
	type?: "COUNTRY" | "CITY" | "AREA" | "ESTATE" | "BUILDING" | "WING" | "FLOOR" | "ROOM" | "LOCKER_BANK" | undefined;
	parent?: LocationAncestry | undefined;
};
export const LocationAncestry: z.ZodType<LocationAncestry> = z.lazy(() =>
	z.object({
		id: z.number(),
		name: z.string(),
		enabled: z.boolean(),
		type: z
			.union([
				z.literal("COUNTRY"),
				z.literal("CITY"),
				z.literal("AREA"),
				z.literal("ESTATE"),
				z.literal("BUILDING"),
				z.literal("WING"),
				z.literal("FLOOR"),
				z.literal("ROOM"),
				z.literal("LOCKER_BANK"),
				z.undefined(),
			])
			.optional(),
		parent: z.union([LocationAncestry, z.undefined()]).optional(),
	})
);
export type Location = z.infer<typeof Location>;
export const Location = z.object({
	id: z.number(),
	name: z.string(),
	type: z
		.union([
			z.literal("COUNTRY"),
			z.literal("CITY"),
			z.literal("AREA"),
			z.literal("ESTATE"),
			z.literal("BUILDING"),
			z.literal("WING"),
			z.literal("FLOOR"),
			z.literal("ROOM"),
			z.literal("LOCKER_BANK"),
			z.undefined(),
		])
		.optional(),
	enabled: z.boolean(),
	parent: z.union([LocationAncestry, z.undefined()]).optional(),
	tenant: z.union([TenantRef, z.undefined()]).optional(),
});

export type CompartmentMutation = z.infer<typeof CompartmentMutation>;
export const CompartmentMutation = z.object({
	number: z.string(),
	size: z.union([z.string(), z.undefined()]).optional(),
	enabled: z.boolean(),
});

export type LockRef = z.infer<typeof LockRef>;
export const LockRef = z.object({
	id: z.string(),
	status: z.union([z.literal("OPEN"), z.literal("CLOSED"), z.undefined()]).optional(),
});

export type Compartment = z.infer<typeof Compartment>;
export const Compartment = z.object({
	id: z.number(),
	number: z.string(),
	tenant: TenantRef,
	location: LocationAncestry,
	enabled: z.boolean(),
	booked: z.boolean(),
	size: z.union([z.string(), z.undefined()]).optional(),
	lock: z.union([LockRef, z.undefined()]).optional(),
	bookable: z.boolean(),
});

export type CompartmentLockMutation = z.infer<typeof CompartmentLockMutation>;
export const CompartmentLockMutation = z.object({
	lockId: z.string().optional(),
});

export type BookingMutation = z.infer<typeof BookingMutation>;
export const BookingMutation = z.object({
	from: z.string(),
	until: z.union([z.string(), z.undefined()]).optional(),
	name: z.union([z.string(), z.undefined()]).optional(),
});

export type UserRef = z.infer<typeof UserRef>;
export const UserRef = z.object({
	username: z.string(),
	displayName: z.string(),
	email: z.union([z.string(), z.undefined()]).optional(),
});

export type CompartmentRef = z.infer<typeof CompartmentRef>;
export const CompartmentRef = z.object({
	id: z.number(),
	number: z.string(),
});

export type Booking = z.infer<typeof Booking>;
export const Booking = z.object({
	id: z.number(),
	users: z.array(UserRef),
	compartment: CompartmentRef,
	location: LocationAncestry,
	lock: z.union([LockRef, z.undefined()]).optional(),
	from: z.string(),
	until: z.union([z.string(), z.undefined()]).optional(),
	name: z.union([z.string(), z.undefined()]).optional(),
	status: z.union([z.literal("ACTIVE"), z.literal("ENDED"), z.literal("FUTURE")]),
});

export type TenantCreation = z.infer<typeof TenantCreation>;
export const TenantCreation = z.object({
	id: z.string().optional(),
	name: z.string().optional(),
});

export type LockEventCreation = z.infer<typeof LockEventCreation>;
export const LockEventCreation = z.object({
	status: z.union([z.literal("OPEN"), z.literal("CLOSED")]),
});

export type LocationCreation = z.infer<typeof LocationCreation>;
export const LocationCreation = z.object({
	name: z.string(),
	type: z
		.union([
			z.literal("COUNTRY"),
			z.literal("CITY"),
			z.literal("AREA"),
			z.literal("ESTATE"),
			z.literal("BUILDING"),
			z.literal("WING"),
			z.literal("FLOOR"),
			z.literal("ROOM"),
			z.literal("LOCKER_BANK"),
			z.undefined(),
		])
		.optional(),
	parentId: z.union([z.number(), z.undefined()]).optional(),
	enabled: z.boolean(),
});

export type CompartmentCreation = z.infer<typeof CompartmentCreation>;
export const CompartmentCreation = z.object({
	number: z.string(),
	size: z.union([z.string(), z.undefined()]).optional(),
	enabled: z.union([z.boolean(), z.undefined()]).optional(),
	locationId: z.number(),
	lock: z.union([z.string(), z.undefined()]).optional(),
});

export type BookingCreation = z.infer<typeof BookingCreation>;
export const BookingCreation = z.object({
	compartmentId: z.number(),
	users: z.array(z.string()),
	from: z.string(),
	until: z.union([z.string(), z.undefined()]).optional(),
	name: z.union([z.string(), z.undefined()]).optional(),
});

export type TokenQuery = z.infer<typeof TokenQuery>;
export const TokenQuery = z.object({
	code: z.string(),
	redirectUri: z.string(),
});

export type AuthenticationToken = z.infer<typeof AuthenticationToken>;
export const AuthenticationToken = z.object({
	id_token: z.string().optional(),
	access_token: z.string().optional(),
	refresh_token: z.string().optional(),
	expires_in: z.number().optional(),
	token_type: z.string().optional(),
});

export type RefreshTokenQuery = z.infer<typeof RefreshTokenQuery>;
export const RefreshTokenQuery = z.object({
	refreshToken: z.string(),
});

export type User = z.infer<typeof User>;
export const User = z.object({
	username: z.string(),
	displayName: z.string(),
	givenName: z.union([z.string(), z.undefined()]).optional(),
	familyName: z.union([z.string(), z.undefined()]).optional(),
	email: z.union([z.string(), z.undefined()]).optional(),
	superUser: z.boolean(),
});

export type SortObject = z.infer<typeof SortObject>;
export const SortObject = z.object({
	direction: z.string().optional(),
	nullHandling: z.string().optional(),
	ascending: z.boolean().optional(),
	property: z.string().optional(),
	ignoreCase: z.boolean().optional(),
});

export type PageableObject = z.infer<typeof PageableObject>;
export const PageableObject = z.object({
	paged: z.boolean().optional(),
	pageNumber: z.number().optional(),
	pageSize: z.number().optional(),
	unpaged: z.boolean().optional(),
	offset: z.number().optional(),
	sort: z.array(SortObject).optional(),
});

export type PageTenant = z.infer<typeof PageTenant>;
export const PageTenant = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(Tenant).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type PageUser = z.infer<typeof PageUser>;
export const PageUser = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(User).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type PageBooking = z.infer<typeof PageBooking>;
export const PageBooking = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(Booking).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type JsonNode = z.infer<typeof JsonNode>;
export const JsonNode = z.unknown();

export type AuditLogEntry = z.infer<typeof AuditLogEntry>;
export const AuditLogEntry = z.object({
	id: z.number(),
	created: z.string(),
	action: z.union([
		z.literal("TENANT_CREATED"),
		z.literal("TENANT_UPDATED"),
		z.literal("TENANT_DELETED"),
		z.literal("TENANT_AUDIT_SETTINGS_UPDATED"),
		z.literal("TENANT_BOOKING_SETTINGS_UPDATED"),
		z.literal("TENANT_COMPARTMENT_SETTINGS_UPDATED"),
		z.literal("LOCATION_CREATED"),
		z.literal("LOCATION_UPDATED"),
		z.literal("LOCATION_DELETED"),
		z.literal("COMPARTMENT_CREATED"),
		z.literal("COMPARTMENT_UPDATED"),
		z.literal("COMPARTMENT_DELETED"),
		z.literal("COMPARTMENT_LOCK_UPDATED"),
		z.literal("COMPARTMENT_BOOKINGS_RETRIEVED"),
		z.literal("BOOKING_CREATED"),
		z.literal("BOOKING_UPDATED"),
		z.literal("BOOKING_DELETED"),
		z.literal("BOOKING_USER_ADDED"),
		z.literal("BOOKING_USER_REMOVED"),
		z.literal("BOOKINGS_RETRIEVED"),
		z.literal("TENANT_USER_CREATED"),
		z.literal("TENANT_USER_DELETED"),
		z.literal("USER_LOGGED_IN"),
		z.literal("USER_ROLE_CREATED"),
		z.literal("USER_ROLE_UPDATED"),
		z.literal("USER_ROLE_DELETED"),
		z.literal("USER_BOOKINGS_RETRIEVED"),
		z.literal("LOCK_OPENED"),
		z.literal("LOCK_CLOSED"),
		z.literal("LOCK_CREATED"),
		z.literal("LOCK_USER_KEY_UPDATED"),
		z.literal("LOCK_TENANT_KEY_UPDATED"),
		z.literal("LOCK_KEYS_RESET"),
		z.literal("TENANT_LOCK_ACQUIRED"),
		z.literal("TENANT_LOCK_RELEASED"),
	]),
	actor: z.union([z.string(), z.undefined()]).optional(),
	data: z.union([JsonNode, z.undefined()]).optional(),
	patch: z.union([JsonNode, z.undefined()]).optional(),
});

export type PageAuditLogEntry = z.infer<typeof PageAuditLogEntry>;
export const PageAuditLogEntry = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(AuditLogEntry).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type TenantSettings = z.infer<typeof TenantSettings>;
export const TenantSettings = z.object({
	booking: TenantBookingSettings,
	audit: TenantAuditSettings,
	compartment: TenantCompartmentSettings,
});

export type Lock = z.infer<typeof Lock>;
export const Lock = z.object({
	id: z.string(),
	status: z.union([z.literal("OPEN"), z.literal("CLOSED"), z.undefined()]).optional(),
	compartment: z.union([CompartmentRef, z.undefined()]).optional(),
});

export type LockKey = z.infer<typeof LockKey>;
export const LockKey = z.object({
	key: z.string(),
	nextKey: z.union([z.string(), z.undefined()]).optional(),
});

export type LockKeys = z.infer<typeof LockKeys>;
export const LockKeys = z.object({
	user: LockKey,
	tenant: LockKey,
});

export type PageLocation = z.infer<typeof PageLocation>;
export const PageLocation = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(Location).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type PageCompartment = z.infer<typeof PageCompartment>;
export const PageCompartment = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(Compartment).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type CompartmentTemplate = z.infer<typeof CompartmentTemplate>;
export const CompartmentTemplate = z.object({
	number: z.string(),
	sizes: z.array(z.string()),
});

export type LocationTree = {
	id: number;
	name: string;
	type?: "COUNTRY" | "CITY" | "AREA" | "ESTATE" | "BUILDING" | "WING" | "FLOOR" | "ROOM" | "LOCKER_BANK" | undefined;
	enabled: boolean;
	children: Array<LocationTree>;
};
export const LocationTree: z.ZodType<LocationTree> = z.lazy(() =>
	z.object({
		id: z.number(),
		name: z.string(),
		type: z
			.union([
				z.literal("COUNTRY"),
				z.literal("CITY"),
				z.literal("AREA"),
				z.literal("ESTATE"),
				z.literal("BUILDING"),
				z.literal("WING"),
				z.literal("FLOOR"),
				z.literal("ROOM"),
				z.literal("LOCKER_BANK"),
				z.undefined(),
			])
			.optional(),
		enabled: z.boolean(),
		children: z.array(LocationTree),
	})
);
export type get_GetTenant = typeof get_GetTenant;
export const get_GetTenant = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: Tenant,
};

export type put_UpdateTenant = typeof put_UpdateTenant;
export const put_UpdateTenant = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: TenantMutation,
	}),
	response: Tenant,
};

export type delete_DeleteTenant = typeof delete_DeleteTenant;
export const delete_DeleteTenant = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.unknown(),
};

export type put_UpdateUserRole = typeof put_UpdateUserRole;
export const put_UpdateUserRole = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}/roles/{userRoleId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
			userRoleId: z.number(),
		}),
		body: UserRoleMutation,
	}),
	response: UserRole,
};

export type delete_DeleteUserRole = typeof delete_DeleteUserRole;
export const delete_DeleteUserRole = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}/roles/{userRoleId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
			userRoleId: z.number(),
		}),
	}),
	response: z.unknown(),
};

export type get_GetTenantCompartmentSettings = typeof get_GetTenantCompartmentSettings;
export const get_GetTenantCompartmentSettings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/settings/compartment"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: TenantCompartmentSettings,
};

export type put_UpdateTenantCompartmentSettings = typeof put_UpdateTenantCompartmentSettings;
export const put_UpdateTenantCompartmentSettings = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/settings/compartment"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: TenantCompartmentSettings,
	}),
	response: z.unknown(),
};

export type get_GetTenantBookingSettings = typeof get_GetTenantBookingSettings;
export const get_GetTenantBookingSettings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/settings/booking"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: TenantBookingSettings,
};

export type put_UpdateTenantBookingSettings = typeof put_UpdateTenantBookingSettings;
export const put_UpdateTenantBookingSettings = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/settings/booking"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: TenantBookingSettings,
	}),
	response: z.unknown(),
};

export type get_GetTenantAuditSettings = typeof get_GetTenantAuditSettings;
export const get_GetTenantAuditSettings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/settings/audit"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: TenantAuditSettings,
};

export type put_UpdateTenantAuditSettings = typeof put_UpdateTenantAuditSettings;
export const put_UpdateTenantAuditSettings = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/settings/audit"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: TenantAuditSettings,
	}),
	response: z.unknown(),
};

export type put_UpdateUserLockKey = typeof put_UpdateUserLockKey;
export const put_UpdateUserLockKey = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}/keys/user"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
		body: LockKeyMutation,
	}),
	response: z.unknown(),
};

export type put_UpdateTenantLockKey = typeof put_UpdateTenantLockKey;
export const put_UpdateTenantLockKey = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}/keys/tenant"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
		body: LockKeyMutation,
	}),
	response: z.unknown(),
};

export type get_GetLocation = typeof get_GetLocation;
export const get_GetLocation = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: Location,
};

export type put_UpdateLocation = typeof put_UpdateLocation;
export const put_UpdateLocation = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
		body: LocationMutation,
	}),
	response: Location,
};

export type delete_DeleteLocation = typeof delete_DeleteLocation;
export const delete_DeleteLocation = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: z.unknown(),
};

export type get_GetCompartment = typeof get_GetCompartment;
export const get_GetCompartment = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
	}),
	response: Compartment,
};

export type put_UpdateCompartment = typeof put_UpdateCompartment;
export const put_UpdateCompartment = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
		body: CompartmentMutation,
	}),
	response: Compartment,
};

export type delete_DeleteCompartment = typeof delete_DeleteCompartment;
export const delete_DeleteCompartment = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
	}),
	response: z.unknown(),
};

export type put_AssignCompartmentLock = typeof put_AssignCompartmentLock;
export const put_AssignCompartmentLock = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}/lock"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
		body: CompartmentLockMutation,
	}),
	response: Compartment,
};

export type get_GetBooking = typeof get_GetBooking;
export const get_GetBooking = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
	}),
	response: Booking,
};

export type put_UpdateBooking = typeof put_UpdateBooking;
export const put_UpdateBooking = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
		body: BookingMutation,
	}),
	response: Booking,
};

export type delete_DeleteBooking = typeof delete_DeleteBooking;
export const delete_DeleteBooking = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
	}),
	response: z.unknown(),
};

export type get_GetBookingLockKey = typeof get_GetBookingLockKey;
export const get_GetBookingLockKey = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/key"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
	}),
	response: LockKey,
};

export type put_UpdateBookingLockKey = typeof put_UpdateBookingLockKey;
export const put_UpdateBookingLockKey = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/key"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
		body: LockKeyMutation,
	}),
	response: z.unknown(),
};

export type get_QueryTenants = typeof get_QueryTenants;
export const get_QueryTenants = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants"),
	parameters: z.object({
		query: z.object({
			search: z.string().optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
	}),
	response: PageTenant,
};

export type post_CreateTenant = typeof post_CreateTenant;
export const post_CreateTenant = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants"),
	parameters: z.object({
		body: TenantCreation,
	}),
	response: Tenant,
};

export type get_GetUserRoles = typeof get_GetUserRoles;
export const get_GetUserRoles = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}/roles"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
	}),
	response: z.array(UserRole),
};

export type post_CreateUserRole = typeof post_CreateUserRole;
export const post_CreateUserRole = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}/roles"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
		body: UserRoleMutation,
	}),
	response: UserRole,
};

export type post_ReportLockEvent = typeof post_ReportLockEvent;
export const post_ReportLockEvent = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}/events"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
		body: LockEventCreation,
	}),
	response: z.unknown(),
};

export type get_QueryLocations = typeof get_QueryLocations;
export const get_QueryLocations = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations"),
	parameters: z.object({
		query: z.object({
			search: z.string().optional(),
			parent: z.number().optional(),
			type: z
				.union([
					z.literal("COUNTRY"),
					z.literal("CITY"),
					z.literal("AREA"),
					z.literal("ESTATE"),
					z.literal("BUILDING"),
					z.literal("WING"),
					z.literal("FLOOR"),
					z.literal("ROOM"),
					z.literal("LOCKER_BANK"),
				])
				.optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageLocation,
};

export type post_CreateLocation = typeof post_CreateLocation;
export const post_CreateLocation = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/locations"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: LocationCreation,
	}),
	response: Location,
};

export type get_QueryCompartments = typeof get_QueryCompartments;
export const get_QueryCompartments = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/compartments"),
	parameters: z.object({
		query: z.object({
			location: z.number().optional(),
			compartmentSize: z.string().optional(),
			status: z.union([z.literal("BOOKED"), z.literal("AVAILABLE")]).optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageCompartment,
};

export type post_CreateCompartment = typeof post_CreateCompartment;
export const post_CreateCompartment = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/compartments"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: CompartmentCreation,
	}),
	response: Compartment,
};

export type get_QueryBookings = typeof get_QueryBookings;
export const get_QueryBookings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/bookings"),
	parameters: z.object({
		query: z.object({
			compartment: z.number().optional(),
			location: z.number().optional(),
			user: z.string().optional(),
			from: z.string().optional(),
			until: z.string().optional(),
			search: z.string().optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageBooking,
};

export type post_CreateBooking = typeof post_CreateBooking;
export const post_CreateBooking = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/bookings"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: BookingCreation,
	}),
	response: Booking,
};

export type post_AddBookingUser = typeof post_AddBookingUser;
export const post_AddBookingUser = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/users/{username}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
			username: z.string(),
		}),
	}),
	response: z.unknown(),
};

export type delete_RemoveBookingUser = typeof delete_RemoveBookingUser;
export const delete_RemoveBookingUser = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/users/{username}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
			username: z.string(),
		}),
	}),
	response: z.unknown(),
};

export type post_AddBookingLockEvent = typeof post_AddBookingLockEvent;
export const post_AddBookingLockEvent = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/lock/events"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
		body: LockEventCreation,
	}),
	response: z.unknown(),
};

export type post_GetAccessToken = typeof post_GetAccessToken;
export const post_GetAccessToken = {
	method: z.literal("POST"),
	path: z.literal("/api/auth/token"),
	parameters: z.object({
		body: TokenQuery,
	}),
	response: AuthenticationToken,
};

export type post_RefreshToken = typeof post_RefreshToken;
export const post_RefreshToken = {
	method: z.literal("POST"),
	path: z.literal("/api/auth/refresh"),
	parameters: z.object({
		body: RefreshTokenQuery,
	}),
	response: AuthenticationToken,
};

export type get_GetCurrentUser = typeof get_GetCurrentUser;
export const get_GetCurrentUser = {
	method: z.literal("GET"),
	path: z.literal("/api/user"),
	parameters: z.never(),
	response: User,
};

export type get_QueryUsers = typeof get_QueryUsers;
export const get_QueryUsers = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/users"),
	parameters: z.object({
		query: z.object({
			search: z.string().optional(),
			role: z.union([z.literal("ADMIN"), z.literal("MANAGER"), z.literal("USER")]).optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageUser,
};

export type get_GetUser = typeof get_GetUser;
export const get_GetUser = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
	}),
	response: User,
};

export type delete_DeleteUser = typeof delete_DeleteUser;
export const delete_DeleteUser = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
	}),
	response: z.unknown(),
};

export type get_GetUserBookings = typeof get_GetUserBookings;
export const get_GetUserBookings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}/bookings"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
	}),
	response: PageBooking,
};

export type get_GetUserAuditLog = typeof get_GetUserAuditLog;
export const get_GetUserAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}/audit"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_GetTenantSettings = typeof get_GetTenantSettings;
export const get_GetTenantSettings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/settings"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: TenantSettings,
};

export type get_GetRoles = typeof get_GetRoles;
export const get_GetRoles = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/roles"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.array(UserRole),
};

export type get_GetLock = typeof get_GetLock;
export const get_GetLock = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
	}),
	response: Lock,
};

export type delete_ReleaseLock = typeof delete_ReleaseLock;
export const delete_ReleaseLock = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
	}),
	response: z.unknown(),
};

export type get_GetLockKeys = typeof get_GetLockKeys;
export const get_GetLockKeys = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}/keys"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
	}),
	response: LockKeys,
};

export type delete_ResetLockKeys = typeof delete_ResetLockKeys;
export const delete_ResetLockKeys = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}/keys"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
	}),
	response: LockKeys,
};

export type get_GetLockAuditLog = typeof get_GetLockAuditLog;
export const get_GetLockAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}/audit"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_GetLocationCompartments = typeof get_GetLocationCompartments;
export const get_GetLocationCompartments = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}/compartments"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: PageCompartment,
};

export type get_GetLocationCompartmentTemplate = typeof get_GetLocationCompartmentTemplate;
export const get_GetLocationCompartmentTemplate = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}/compartments/template"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: CompartmentTemplate,
};

export type get_GetLocationAuditLog = typeof get_GetLocationAuditLog;
export const get_GetLocationAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}/audit"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_GetLocationTrees = typeof get_GetLocationTrees;
export const get_GetLocationTrees = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/location-trees"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.array(LocationTree),
};

export type get_GetCompartmentBookings = typeof get_GetCompartmentBookings;
export const get_GetCompartmentBookings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}/bookings"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
	}),
	response: PageBooking,
};

export type get_GetCompartmentAuditLog = typeof get_GetCompartmentAuditLog;
export const get_GetCompartmentAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}/audit"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_GetBookingAuditLog = typeof get_GetBookingAuditLog;
export const get_GetBookingAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/audit"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_QueryTenantAuditLog = typeof get_QueryTenantAuditLog;
export const get_QueryTenantAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/audit"),
	parameters: z.object({
		query: z.object({
			from: z.string().optional(),
			until: z.string().optional(),
			booking: z.number().optional(),
			compartment: z.number().optional(),
			location: z.number().optional(),
			username: z.string().optional(),
			lock: z.string().optional(),
			actor: z.string().optional(),
			action: z
				.union([
					z.literal("TENANT_CREATED"),
					z.literal("TENANT_UPDATED"),
					z.literal("TENANT_DELETED"),
					z.literal("TENANT_AUDIT_SETTINGS_UPDATED"),
					z.literal("TENANT_BOOKING_SETTINGS_UPDATED"),
					z.literal("TENANT_COMPARTMENT_SETTINGS_UPDATED"),
					z.literal("LOCATION_CREATED"),
					z.literal("LOCATION_UPDATED"),
					z.literal("LOCATION_DELETED"),
					z.literal("COMPARTMENT_CREATED"),
					z.literal("COMPARTMENT_UPDATED"),
					z.literal("COMPARTMENT_DELETED"),
					z.literal("COMPARTMENT_LOCK_UPDATED"),
					z.literal("COMPARTMENT_BOOKINGS_RETRIEVED"),
					z.literal("BOOKING_CREATED"),
					z.literal("BOOKING_UPDATED"),
					z.literal("BOOKING_DELETED"),
					z.literal("BOOKING_USER_ADDED"),
					z.literal("BOOKING_USER_REMOVED"),
					z.literal("BOOKINGS_RETRIEVED"),
					z.literal("TENANT_USER_CREATED"),
					z.literal("TENANT_USER_DELETED"),
					z.literal("USER_LOGGED_IN"),
					z.literal("USER_ROLE_CREATED"),
					z.literal("USER_ROLE_UPDATED"),
					z.literal("USER_ROLE_DELETED"),
					z.literal("USER_BOOKINGS_RETRIEVED"),
					z.literal("LOCK_OPENED"),
					z.literal("LOCK_CLOSED"),
					z.literal("LOCK_CREATED"),
					z.literal("LOCK_USER_KEY_UPDATED"),
					z.literal("LOCK_TENANT_KEY_UPDATED"),
					z.literal("LOCK_KEYS_RESET"),
					z.literal("TENANT_LOCK_ACQUIRED"),
					z.literal("TENANT_LOCK_RELEASED"),
				])
				.optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_Logout = typeof get_Logout;
export const get_Logout = {
	method: z.literal("GET"),
	path: z.literal("/api/auth/logout"),
	parameters: z.object({
		query: z.object({
			redirectUri: z.string(),
		}),
	}),
	response: z.unknown(),
};

export type get_Login = typeof get_Login;
export const get_Login = {
	method: z.literal("GET"),
	path: z.literal("/api/auth/login"),
	parameters: z.object({
		query: z.object({
			email: z.union([z.string(), z.undefined()]),
			tenantId: z.union([z.string(), z.undefined()]),
			redirectUri: z.string(),
			state: z.union([z.string(), z.undefined()]),
		}),
	}),
	response: z.unknown(),
};

// <EndpointByMethod>
export const EndpointByMethod = {
	get: {
		"/api/tenants/{tenantId}": get_GetTenant,
		"/api/tenants/{tenantId}/settings/compartment": get_GetTenantCompartmentSettings,
		"/api/tenants/{tenantId}/settings/booking": get_GetTenantBookingSettings,
		"/api/tenants/{tenantId}/settings/audit": get_GetTenantAuditSettings,
		"/api/tenants/{tenantId}/locations/{locationId}": get_GetLocation,
		"/api/tenants/{tenantId}/compartments/{compartmentId}": get_GetCompartment,
		"/api/tenants/{tenantId}/bookings/{bookingId}": get_GetBooking,
		"/api/tenants/{tenantId}/bookings/{bookingId}/key": get_GetBookingLockKey,
		"/api/tenants": get_QueryTenants,
		"/api/tenants/{tenantId}/users/{username}/roles": get_GetUserRoles,
		"/api/tenants/{tenantId}/locations": get_QueryLocations,
		"/api/tenants/{tenantId}/compartments": get_QueryCompartments,
		"/api/tenants/{tenantId}/bookings": get_QueryBookings,
		"/api/user": get_GetCurrentUser,
		"/api/tenants/{tenantId}/users": get_QueryUsers,
		"/api/tenants/{tenantId}/users/{username}": get_GetUser,
		"/api/tenants/{tenantId}/users/{username}/bookings": get_GetUserBookings,
		"/api/tenants/{tenantId}/users/{username}/audit": get_GetUserAuditLog,
		"/api/tenants/{tenantId}/settings": get_GetTenantSettings,
		"/api/tenants/{tenantId}/roles": get_GetRoles,
		"/api/tenants/{tenantId}/locks/{lockId}": get_GetLock,
		"/api/tenants/{tenantId}/locks/{lockId}/keys": get_GetLockKeys,
		"/api/tenants/{tenantId}/locks/{lockId}/audit": get_GetLockAuditLog,
		"/api/tenants/{tenantId}/locations/{locationId}/compartments": get_GetLocationCompartments,
		"/api/tenants/{tenantId}/locations/{locationId}/compartments/template": get_GetLocationCompartmentTemplate,
		"/api/tenants/{tenantId}/locations/{locationId}/audit": get_GetLocationAuditLog,
		"/api/tenants/{tenantId}/location-trees": get_GetLocationTrees,
		"/api/tenants/{tenantId}/compartments/{compartmentId}/bookings": get_GetCompartmentBookings,
		"/api/tenants/{tenantId}/compartments/{compartmentId}/audit": get_GetCompartmentAuditLog,
		"/api/tenants/{tenantId}/bookings/{bookingId}/audit": get_GetBookingAuditLog,
		"/api/tenants/{tenantId}/audit": get_QueryTenantAuditLog,
		"/api/auth/logout": get_Logout,
		"/api/auth/login": get_Login,
	},
	put: {
		"/api/tenants/{tenantId}": put_UpdateTenant,
		"/api/tenants/{tenantId}/users/{username}/roles/{userRoleId}": put_UpdateUserRole,
		"/api/tenants/{tenantId}/settings/compartment": put_UpdateTenantCompartmentSettings,
		"/api/tenants/{tenantId}/settings/booking": put_UpdateTenantBookingSettings,
		"/api/tenants/{tenantId}/settings/audit": put_UpdateTenantAuditSettings,
		"/api/tenants/{tenantId}/locks/{lockId}/keys/user": put_UpdateUserLockKey,
		"/api/tenants/{tenantId}/locks/{lockId}/keys/tenant": put_UpdateTenantLockKey,
		"/api/tenants/{tenantId}/locations/{locationId}": put_UpdateLocation,
		"/api/tenants/{tenantId}/compartments/{compartmentId}": put_UpdateCompartment,
		"/api/tenants/{tenantId}/compartments/{compartmentId}/lock": put_AssignCompartmentLock,
		"/api/tenants/{tenantId}/bookings/{bookingId}": put_UpdateBooking,
		"/api/tenants/{tenantId}/bookings/{bookingId}/key": put_UpdateBookingLockKey,
	},
	delete: {
		"/api/tenants/{tenantId}": delete_DeleteTenant,
		"/api/tenants/{tenantId}/users/{username}/roles/{userRoleId}": delete_DeleteUserRole,
		"/api/tenants/{tenantId}/locations/{locationId}": delete_DeleteLocation,
		"/api/tenants/{tenantId}/compartments/{compartmentId}": delete_DeleteCompartment,
		"/api/tenants/{tenantId}/bookings/{bookingId}": delete_DeleteBooking,
		"/api/tenants/{tenantId}/bookings/{bookingId}/users/{username}": delete_RemoveBookingUser,
		"/api/tenants/{tenantId}/users/{username}": delete_DeleteUser,
		"/api/tenants/{tenantId}/locks/{lockId}": delete_ReleaseLock,
		"/api/tenants/{tenantId}/locks/{lockId}/keys": delete_ResetLockKeys,
	},
	post: {
		"/api/tenants": post_CreateTenant,
		"/api/tenants/{tenantId}/users/{username}/roles": post_CreateUserRole,
		"/api/tenants/{tenantId}/locks/{lockId}/events": post_ReportLockEvent,
		"/api/tenants/{tenantId}/locations": post_CreateLocation,
		"/api/tenants/{tenantId}/compartments": post_CreateCompartment,
		"/api/tenants/{tenantId}/bookings": post_CreateBooking,
		"/api/tenants/{tenantId}/bookings/{bookingId}/users/{username}": post_AddBookingUser,
		"/api/tenants/{tenantId}/bookings/{bookingId}/lock/events": post_AddBookingLockEvent,
		"/api/auth/token": post_GetAccessToken,
		"/api/auth/refresh": post_RefreshToken,
	},
};
export type EndpointByMethod = typeof EndpointByMethod;
// </EndpointByMethod>

// <EndpointByMethod.Shorthands>
export type GetEndpoints = EndpointByMethod["get"];
export type PutEndpoints = EndpointByMethod["put"];
export type DeleteEndpoints = EndpointByMethod["delete"];
export type PostEndpoints = EndpointByMethod["post"];
export type AllEndpoints = EndpointByMethod[keyof EndpointByMethod];
// </EndpointByMethod.Shorthands>

// <ApiClientTypes>
export type EndpointParameters = {
	body?: unknown;
	query?: Record<string, unknown>;
	header?: Record<string, unknown>;
	path?: Record<string, unknown>;
};

export type MutationMethod = "post" | "put" | "patch" | "delete";
export type Method = "get" | "head" | MutationMethod;

export type DefaultEndpoint = {
	parameters?: EndpointParameters | undefined;
	response: unknown;
};

export type Endpoint<TConfig extends DefaultEndpoint = DefaultEndpoint> = {
	operationId: string;
	method: Method;
	path: string;
	parameters?: TConfig["parameters"];
	meta: {
		alias: string;
		hasParameters: boolean;
		areParametersRequired: boolean;
	};
	response: TConfig["response"];
};

type Fetcher = (
	method: Method,
	url: string,
	parameters?: EndpointParameters | undefined,
) => Promise<Endpoint["response"]>;

type RequiredKeys<T> = {
	[P in keyof T]-?: undefined extends T[P] ? never : P;
}[keyof T];

type MaybeOptionalArg<T> = RequiredKeys<T> extends never ? [config?: T] : [config: T];

// </ApiClientTypes>

// <ApiClient>
export class ApiClient {
	baseUrl: string = "";

	constructor(public fetcher: Fetcher) {}

	setBaseUrl(baseUrl: string) {
		this.baseUrl = baseUrl;
		return this;
	}

	// <ApiClient.get>
	get<Path extends keyof GetEndpoints, TEndpoint extends GetEndpoints[Path]>(
		path: Path,
		...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
	): Promise<z.infer<TEndpoint["response"]>> {
		return this.fetcher("get", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
	}
	// </ApiClient.get>

	// <ApiClient.put>
	put<Path extends keyof PutEndpoints, TEndpoint extends PutEndpoints[Path]>(
		path: Path,
		...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
	): Promise<z.infer<TEndpoint["response"]>> {
		return this.fetcher("put", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
	}
	// </ApiClient.put>

	// <ApiClient.delete>
	delete<Path extends keyof DeleteEndpoints, TEndpoint extends DeleteEndpoints[Path]>(
		path: Path,
		...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
	): Promise<z.infer<TEndpoint["response"]>> {
		return this.fetcher("delete", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
	}
	// </ApiClient.delete>

	// <ApiClient.post>
	post<Path extends keyof PostEndpoints, TEndpoint extends PostEndpoints[Path]>(
		path: Path,
		...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
	): Promise<z.infer<TEndpoint["response"]>> {
		return this.fetcher("post", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
	}
	// </ApiClient.post>
}

export function createApiClient(fetcher: Fetcher, baseUrl?: string) {
	return new ApiClient(fetcher).setBaseUrl(baseUrl ?? "");
}

/**
 Example usage:
 const api = createApiClient((method, url, params) =>
   fetch(url, { method, body: JSON.stringify(params) }).then((res) => res.json()),
 );
 api.get("/users").then((users) => console.log(users));
 api.post("/users", { body: { name: "John" } }).then((user) => console.log(user));
 api.put("/users/:id", { path: { id: 1 }, body: { name: "John" } }).then((user) => console.log(user));
*/

// </ApiClient
