import {IconButton} from "@mui/material";
import {BoxNumber} from "@variocube/app-ui";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Compartment, CompartmentRef} from "../../api";
import {useTenantId} from "../../tenant";

interface CompartmentLinkIconButtonProps {
	compartment: Compartment | CompartmentRef;
}

export function CompartmentLinkIconButton({compartment}: CompartmentLinkIconButtonProps) {
	const tenantId = useTenantId();
	return (
		<IconButton component={RouterLink} to={`/${tenantId}/compartments/${compartment.id}`}>
			<BoxNumber number={compartment.number} />
		</IconButton>
	);
}
