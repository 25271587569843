import {ErrorAlert} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {TenantBookingSettings, useTenantApi} from "../../api";
import {useReloadTenant, useTenantId} from "../../tenant";
import {BookingSettingsEditForm} from "./BookingSettingsEditForm";

export function BookingSettings() {
	const tenantId = useTenantId();
	const {getTenantBookingSettings, updateTenantBookingSettings} = useTenantApi();
	const reloadTenant = useReloadTenant();
	const {loading, error, result} = useAsync(getTenantBookingSettings, [tenantId]);

	async function handleSave(bookingSettings: TenantBookingSettings) {
		await updateTenantBookingSettings(tenantId, bookingSettings);
		await reloadTenant();
	}

	if (error) {
		return <ErrorAlert error={error} />;
	}

	return <BookingSettingsEditForm loading={loading} onSave={handleSave} bookingSettings={result} />;
}
